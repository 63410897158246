import * as React from 'react';
import {Link, useParams} from 'react-router-dom';

import {
    Steps,
    Button,
    Collapse,
    Form,
    Table,
    Space, Modal, notification
} from 'antd';

const {Step} = Steps;
const {Panel} = Collapse;

import SiteLayout from '../Components/Styled/SiteLayout';
import {useEffect, useState} from "react";
import {
    PlusCircleOutlined
} from "@ant-design/icons";
import ApiWrapper from "../Utils/ApiWrapper";

import uuid from 'react-uuid'
import Element from "../Components/Element";
import {getActiveAccountId} from "../Utils/helpers";
import Raum from "../Components/Raum";
import Gebaeude from "../Components/Gebaeude";

const ElementCreateGuidedContainer = (props) => {

    const params = useParams();
    const objektId = params.objektId;

    const [gebaudeImObjekt, setGebaudeImObjekt] = useState([]);

    const [raumKategeorien, setRaumKategeorien] = useState([{
        id: '1111', titel: 'Sonstige', cat: 'Allgemein'
    }]);
    const [elementKategeorien, setElementKategeorien] = useState([]);

    // Gebäude
    const [activGebaeude, setActivGebaeude] = useState({});
    const [activGebaeudeteil, setActivGebaeudeteil] = useState({});

    const [raumKatIdForNextRaum, setRaumKatIdForNextRaum] = useState('');
    const [kategorieForNextElemente, setKategorieForNextElemente] = useState('');

    const [raumDetailsToEdit, setRaumDetailsToEdit] = useState({});

    const [isCreateGebaeudeModalVisible, setIsCreateGebaeudeModalVisible] = useState(false);
    const [isCreateRoomModalVisible, setIsCreateRoomModalVisible] = useState(false);
    const [isCreateElementModalVisible, setIsCreateElementModalVisible] = useState(false);

    const [current, setCurrent] = React.useState(0);

    const [loading, setLoading] = useState(true);

    const loadKategorien = async () => {

        ApiWrapper.get('objekt-verwaltung-api', `/${getActiveAccountId()}/objekte/${objektId}/elemente/pruefungsarten`, {}).then((result) => {
            const parsedBody = JSON.parse(result.body);
            const parsedBodyAsArray = Array.isArray(parsedBody) ? parsedBody : [parsedBody];
            setElementKategeorien(parsedBodyAsArray);
        }).catch(err => {
            console.log(err);
        });

    };

    const setData = async () => {
        const accountData = props.accountData;

        if (accountData && accountData.objekte) {
            const currentObject = accountData.objekte.find(objekt => objekt.id === objektId);

            if (currentObject && currentObject.buildings) {
                setGebaudeImObjekt(currentObject.buildings);
            }

        }
    }

    useEffect(async () => {
        setLoading(true);
        await setData();
        setLoading(false);
    }, [props.accountData]);

    useEffect(async () => {
        //setLoading(true);
        await loadKategorien();
        //setLoading(false);
    }, []);

    const next = () => {
        setCurrent(current + 1);
    };

    const prev = () => {
        setCurrent(current - 1);
    };

    const step_uebersicht = () => {

        const [form_gebaude] = Form.useForm();

        const genExtra = () => (
          <Button
            onClick={event => {
                // If you don't want click extra trigger collapse, you can prevent this:
                event.stopPropagation();
                form_gebaude.resetFields();
                setIsCreateGebaeudeModalVisible(true);
            }}>
              Hinzufügen
            </Button>
      );

        const gebaeudeBearbeiten = (gebaeudeId) => {
            form_gebaude.resetFields();
            setActivGebaeude(gebaudeImObjekt.find(gebaeude => gebaeude.id === gebaeudeId));
            setIsCreateGebaeudeModalVisible(true);
        }

        const gebaeudeteileBearbeiten = (gebaeudeId) => {
            setActivGebaeude(gebaudeImObjekt.find(gebaeude => gebaeude.id === gebaeudeId));
            next();
        }

        const onOk = async () => {
            const values = await form_gebaude.getFieldsValue(true);

            if (!values.id) {
                ApiWrapper.post('objekt-verwaltung-api', `/${getActiveAccountId()}/object/${objektId}/building`, values).then(async (result) => {
                    const body = JSON.parse(result.body);
                    body.parts = [];
                    setGebaudeImObjekt([...gebaudeImObjekt, body]);
                    setIsCreateGebaeudeModalVisible(false);
                }).catch(err => {
                    console.log(err);
                    setIsCreateGebaeudeModalVisible(false);
                });
            } else {
                ApiWrapper.put('objekt-verwaltung-api', `/${getActiveAccountId()}/object/${objektId}/building`, values).then(async (result) => {
                    const body = JSON.parse(result.body);
                    const oldGebaeude = gebaudeImObjekt.find(gb => gb.id === body.id);
                    body.parts = oldGebaeude.parts;
                    setGebaudeImObjekt(gebaudeImObjekt.map(gb => gb.id === body.id ? body : gb));
                    setIsCreateGebaeudeModalVisible(false);
                }).catch(err => {
                    console.log(err);
                    setIsCreateGebaeudeModalVisible(false);
                });

            }

            //next();
        }

        const onClose = () => {
            setIsCreateGebaeudeModalVisible(false);
        }

        const columns = [
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: 'Beschreibung',
                dataIndex: 'description',
                key: 'description',
            },
            {
                title: 'Aktion',
                key: 'action',
                render: (text, record) => (
                    <Space size="middle">
                        <a href="#"
                           onClick={() => gebaeudeteileBearbeiten(record.id)}>Teile</a>
                        <a href="#"
                           onClick={() => gebaeudeBearbeiten(record.id)}>Bearbeiten</a>
                        <a>Löschen</a>
                    </Space>
                ),
            },
        ];

        return (<React.Fragment>
                <h2>Gebäude</h2>
                <Collapse
                    defaultActiveKey={['1']}
                >
                    <Panel header="Gebäude" key="1" extra={genExtra()}>
                        <Table columns={columns} dataSource={gebaudeImObjekt} loading={loading}/>
                    </Panel>
                </Collapse>
                <Modal
                    title="Gebäude anlegen/bearbeiten"
                    visible={isCreateGebaeudeModalVisible}
                    onOk={onOk}
                    onCancel={onClose}
                >
                    <Gebaeude
                        form={form_gebaude}
                        raumDetails={activGebaeude}
                    />
                </Modal>
                <Button type="primary" style={{marginLeft: 8, marginRight: 8}} onClick={() => setCurrent(3)}>
                    Fertig und Anlegen
                </Button>
                <Button type="primary" style={{marginLeft: 8, marginRight: 8}}>
                    Abbrechen
                </Button>
            </React.Fragment>
        )
    }

    const step_gebauede = () => {

        const [form_raume] = Form.useForm();

        const genExtra = (raumKategeorieId) => (
          <Button
            onClick={event => {
                // If you don't want click extra trigger collapse, you can prevent this:
                event.stopPropagation();
                form_raume.resetFields();
                setRaumKatIdForNextRaum(raumKategeorieId);
                setIsCreateRoomModalVisible(true);
            }}>
                Hinzufügen
            </Button>
      );

        const raumBearbeiten = (gebaeudeteilId) => {
            form_raume.resetFields();
            setRaumDetailsToEdit(activGebaeude.parts.find(gbt => gbt.id === gebaeudeteilId) ?? {});
            setIsCreateRoomModalVisible(true);
        }

        const raumElementeBearbeiten = (gebaeudeteilId) => {
            setActivGebaeudeteil(activGebaeude.parts.find(gbt => gbt.id === gebaeudeteilId));
            next();
        }

        const onOk = async () => {
            const values = await form_raume.getFieldsValue(true);

            if (!values.id) {
                values.categoryId = raumKatIdForNextRaum;
                ApiWrapper.post('objekt-verwaltung-api', `/${getActiveAccountId()}/object/${objektId}/building/${activGebaeude.id}/part`, values).then(async (result) => {
                    const body = JSON.parse(result.body);

                    body.elemente = [];
                    activGebaeude.parts.push(body);
                    setActivGebaeude(activGebaeude);
                    //setActivGebaeudeteil(values);
                    //next();
                    setIsCreateRoomModalVisible(false);
                }).catch(err => {
                    console.log(err);
                    setIsCreateRoomModalVisible(false);
                });
            } else {
                ApiWrapper.put('objekt-verwaltung-api', `/${getActiveAccountId()}/object/${objektId}/building/${activGebaeude.id}/part`, values).then(async (result) => {
                    const body = JSON.parse(result.body);
                    const oldGebaeudeteil = activGebaeude.parts.find(gbt => gbt.id === body.id);
                    //body.kategorieId = oldGebaeudeteil.kategorieId;
                    body.elemente = oldGebaeudeteil.elemente;
                    activGebaeude.parts = activGebaeude.parts.map(gbt => gbt.id === body.id ? body : gbt);
                    setActivGebaeude(activGebaeude);
                    setIsCreateRoomModalVisible(false);
                }).catch(err => {
                    console.log(err);
                    setIsCreateRoomModalVisible(false);
                });
            }

        }

        const onClose = () => {
            setIsCreateRoomModalVisible(false);
        }

        const renderRaumKategeorie = () => {

            const elemente = [];

            const columns = [
                {
                    title: 'Name',
                    dataIndex: 'name',
                    key: 'name',
                },
                {
                    title: 'Beschreibung',
                    dataIndex: 'description',
                    key: 'description',
                },
                {
                    title: 'Etage',
                    key: 'floor',
                    dataIndex: 'floor',
                },
                {
                    title: 'Raum Nr.',
                    key: 'number',
                    dataIndex: 'number',
                },
                {
                    title: 'Aktion',
                    key: 'action',
                    render: (text, record) => (
                        <Space size="middle">
                            <a href="#"
                               onClick={() => raumElementeBearbeiten(record.id)}>Elemente</a>
                            <a href="#"
                               onClick={() => raumBearbeiten(record.id)}>Bearbeiten</a>
                            <a>Löschen</a>
                        </Space>
                    ),
                },
            ];

            for (const raumKategeorie of raumKategeorien) {
                let ds = [];
                if (activGebaeude && Array.isArray(activGebaeude.parts)) {
                    ds = activGebaeude.parts.filter(gbt => gbt.categoryId === raumKategeorie.id)
                }

                elemente.push(
                    <Panel header={raumKategeorie.titel} key="1" extra={genExtra(raumKategeorie.id)}>
                        <Table columns={columns} dataSource={ds}/>
                    </Panel>
                );
            }
            return elemente;
        }

        return (<React.Fragment>
                <h2>Gebäudeteile in {activGebaeude.name}</h2>
                <Collapse
                    defaultActiveKey={['1']}
                >
                    {renderRaumKategeorie()}
                </Collapse>
                <Modal
                    title="Raum anlegen/bearbeiten"
                    visible={isCreateRoomModalVisible}
                    onOk={onOk}
                    onCancel={onClose}
                >
                    <Raum
                        form={form_raume}
                        raumDetails={raumDetailsToEdit}
                    />
                </Modal>
                <Button type="primary" style={{marginLeft: 8, marginRight: 8}} onClick={() => prev()}>
                    Fertig
                </Button>
            </React.Fragment>
        )
    }

    const step_raum = () => {

        const [form_elemente] = Form.useForm();

        const genExtra = (elementKategorie) => (
          <Button
            onClick={event => {
                // If you don't want click extra trigger collapse, you can prevent this:
                event.stopPropagation();
                form_elemente.resetFields();
                setKategorieForNextElemente(elementKategorie);
                setIsCreateElementModalVisible(true);
            }}>
                Hinzufügen
            </Button>
      );

        const onOk = async () => {
            const values = await form_elemente.getFieldsValue(true);
            console.log(values);

            ApiWrapper.post('objekt-verwaltung-api', `/${getActiveAccountId()}/object/${objektId}/building/${activGebaeude.id}/part/${activGebaeudeteil.id}/observable`, values).then(async (result) => {
                const body = JSON.parse(result.body);
                activGebaeudeteil.elemente.push(body);
                setActivGebaeudeteil(activGebaeudeteil);
                setIsCreateElementModalVisible(false);

            }).catch(err => {
                console.log(err);
                setIsCreateElementModalVisible(false);
            });
        }


        const onClose = () => {
            setIsCreateElementModalVisible(false);
        };

        const elementBearbeiten = (raumKategeorieId, raumId) => {
            setIsCreateElementModalVisible(true);
        }

        const renderRaumElemente = () => {

            const elemente = [];

            const columns = [
                {
                    title: 'Name',
                    dataIndex: 'titel',
                    key: 'titel',
                },
                {
                    title: 'Beschreibung',
                    dataIndex: 'description',
                    key: 'description',
                },
                {
                    title: 'Kategorie',
                    dataIndex: 'categoryId',
                    key: 'categoryId',
                },
                {
                    title: 'Aktion',
                    key: 'action',
                    render: (text, record) => (
                        <Space size="middle">
                            <a href="#"
                               onClick={() => elementBearbeiten(record.raumKatIdForNextRaum, record.id)}>Bearbeiten</a>
                            <a>Löschen</a>
                        </Space>
                    ),
                },
            ];

            for (const [i, elementKategorie] of elementKategeorien.entries()) {

                let ds = [];
                if (activGebaeudeteil && Array.isArray(activGebaeudeteil.elemente)) {
                    ds = activGebaeudeteil.elemente.filter(element => element.categoryId === elementKategorie.id)
                }

                elemente.push(
                    <Panel header={elementKategorie.titel} key={i} extra={genExtra(elementKategorie.id)}>
                        <Table columns={columns} dataSource={ds}/>
                    </Panel>
                );
            }

            return elemente;
        }

        const element = {
            categoryId: kategorieForNextElemente
        };

        return (<React.Fragment>
                <h2>Elemente im Gebäudeteil {activGebaeudeteil.name}</h2>
                <Collapse
                >
                    {renderRaumElemente()}
                </Collapse>
                <Button type="primary" style={{marginLeft: 8, marginRight: 8}} onClick={() => prev()}>
                    Fertig
                </Button>
                <Modal
                    title="Element anlegen/bearbeiten"
                    visible={isCreateElementModalVisible}
                    onOk={onOk}
                    onCancel={onClose}
                    width={1000}
                >
                    <Element form={form_elemente} objektId={objektId} element={element}/>
                </Modal>
            </React.Fragment>

        )
    }

    const step_summary = () => {
        return (
            <div>
                {JSON.stringify(gebaudeImObjekt)}
            </div>
        );
    }

    const steps = [{
        title: 'Gebäude', content: step_uebersicht(),
    }, {
        title: 'Gebäudeteile', content: step_gebauede(),
    }, {
        title: 'Elemente', content: step_raum(),
    }, {
        title: 'Abschluss', content: step_summary(),
    },];

    return (

        <SiteLayout pageTitle="Element Anlegen" breadcumItems={[<Link to={`/objekte`}>Account</Link>,
            <Link to={`/objekt/${objektId}`}>Objekt</Link>,]}>
            <Steps progressDot current={current}>
                {steps.map(item => (<Step key={item.title} title={item.title}/>))}
            </Steps>
            <div className="steps-content">{steps[current].content}</div>
        </SiteLayout>);
};

export default ElementCreateGuidedContainer;
