import React, {useState, useEffect, useContext} from 'react';
import SiteLayout from '../Components/Styled/SiteLayout';
import {Link, useNavigate  } from 'react-router-dom';

import {makeStyles} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";


import {Button, Space, PageHeader} from 'antd';
import {StarOutlined, CheckCircleOutlined, CloseCircleOutlined,} from '@ant-design/icons';
import {getActiveAccountName} from "../Utils/helpers";
import {getActiveAccountId} from "../Utils/helpers";
import {isAdmin} from "../Utils/helpers";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    container: {
        display: "flex"
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: "left",
        color: theme.palette.text.secondary,
        height: '100%'
    },
    paperTag: {
        padding: theme.spacing(0),
        textAlign: "center",
        height: '100%',
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: green[100],
        border: `2px solid ${green[400]}`
    },
    center: {
        height:"100%",
        width:"100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
}));

const ObjectListContainer = (props) => {

    let navigate = useNavigate(); ;

    const IconText = ({icon, text}) => (
        <Space>
            {React.createElement(icon)}
            {text}
        </Space>
    );

    const classes = useStyles();

    const accountId = getActiveAccountId();

    const renderTags = (object) => {
        const tags = [];

        if (object.fehler) {
            tags.push(
                <Paper
                    className={classes.paperTag}
                    elevation={0}
                    key={object.id + " - Fehler"}
                    variant="outlined"
                    style={{backgroundColor: red[100],border: `2px solid ${red[400]}`}}
                    onClick = {() => navigate(`/${accountId}/pruefungen?objektId=${object.id}`)}
                >
                    <Button className={classes.center} variant="raised" style={{color: 'red', backgroundColor: red[100], padding: "0px", border: "none"}}>
                           <div>{object.ueberfaelligePruefungen} <br />  überfällige {object.ueberfaelligePruefungen > 1 ? 'Prüfungen' : "Prüfung"}</div>
                    </Button>
                </Paper>

            )
        } else {
            tags.push(
                <Paper
                    className={classes.paperTag}
                    key={object.id + " - Fehler"}
                    elevation={0}
                    variant="outlined"
                >
                    <div className={classes.center} style={{color: 'green'}}>
                           Keine überfälligen Prüfungen
                    </div>
                </Paper>
            )
        }

        return tags;
    }

    const renderObjectPapers = () => {
        const papers = [];
        const accountData = props.accountData;
        if (accountData && accountData.objekte) {
            for (const objekt of accountData.objekte) {
                if (accountId === objekt.accountId) {
                    // Nur Objekte des Accounts anzeigen !!! TODO Es fehlt noch "nur wenn user Recht lesen für Objekt hat"
                    objekt.ueberfaelligePruefungen = 0;
                    for (const building of objekt.buildings) {
                        for (const part of building.parts) {
                            for (const element of part.elemente) {
                                //console.log(element)
                                for (const pruefung of element.naechstePruefungen) {
                                    if (Date.parse(pruefung.naechstePruefung) < new Date() && !pruefung.preview) {
                                        //console.log(pruefung)
                                        objekt.fehler = true;
                                        objekt.ueberfaelligePruefungen = objekt.ueberfaelligePruefungen + 1;
                                    }
                                }
                            }
                        }
                    }

                    papers.push(
                        <Grid
                            item xs={12} sm={6} md={6}
                            key={objekt.id + " - Grid"}
                        >
                            <Paper className={classes.paper} key={objekt.id + " - Paper"}>
                              <table width="100%" height="100%">
                                  <tbody>
                                      <tr width="100%" height="100%">
                                          <td width="70%" height="100%">
                                              <h2><b>{objekt.titel}</b></h2>
                                              <div style={{fontSize: 18}}>
                                                  {objekt.beschreibung}
                                                  <br/>
                                                  {objekt.street},&nbsp; {objekt.city}
                                              </div>
                                              <br/>
                                              <Link style={{fontSize: 18, marginRight: "15px"}} to={`/objekt/${objekt.id}/`}>
                                                  <IconText icon={StarOutlined} text="Details" key={objekt.id + "- details"}/>
                                              </Link>

                                              <Link style={{fontSize: 18, marginRight: "15px"}}
                                                    to={`/${accountId}/pruefungen?objektId=${objekt.id}`}>
                                                  <IconText icon={StarOutlined} text="Nächste Prüfung"
                                                            key={objekt.id + "- naechstePruefungen"}/>
                                              </Link>
                                          </td>
                                          <td width="30%" height="100%">
                                                {renderTags(objekt)}
                                          </td>
                                      </tr>
                                  </tbody>
                              </table>
                            </Paper>
                        </Grid>
                    );
                } else {
                }
                ;
            }
        }
        return papers;
    };

    const renderActionsIfAdmin = () => {
        const actions = [];

        if (isAdmin()) {
            actions.push(<Button type="primary">
                <Link to={`/objekt/create`}>Neues Objekt anlegen</Link>
            </Button>);
        }

        return actions;
    };

    return (
        <SiteLayout pageTitle="Detailansicht Element" breadcumItems={[
            <Link to={`/uebersicht`}>Startseite</Link>,
            'Objektübersicht'
        ]}>
            <PageHeader
                title={`Objektübersicht`}
                subTitle={`${getActiveAccountName()}`}
                ghost={false}
                extra={renderActionsIfAdmin()}>
            </PageHeader>
            <br/>

            <Grid
                container spacing={4}>
                {renderObjectPapers()}
            </Grid>
        </SiteLayout>
    );
};


export default ObjectListContainer;
