import React, {useState, useEffect, useContext, useCallback} from 'react';
import ApiWrapper from '../Utils/ApiWrapper';

import {Link, useNavigate, useParams} from 'react-router-dom';
import SiteLayout from '../Components/Styled/SiteLayout';
import ElementList from '../Components/Styled/ElementList';

import {Statistic, Row, Col, PageHeader, Button, Card, Modal, notification, Table, Tag, Collapse} from 'antd';
import {getActiveAccountId, isAdmin} from "../Utils/helpers";

import moment from 'moment';
import CollapsibleCard from "../Components/Styled/CollapsibleCard";

const {Panel} = Collapse;



const ObjectDetailsContainer = (props) => {

    const [objektDetails, setObjektDetails] = useState({});
    const [anzahlElemente, setAnzahlElemente] = useState(0);
    const [buildings, setBuildings] = useState([]);
    const [elemente, setElemente] = useState([]);
    const [loading, setLoading] = useState(true);

    const params = useParams();

    const objektId = params.objektId;

    const [visible, setVisible] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);

    const [datumLetztePruefung, setDatumLetztePruefung] = useState("");
    const [datumNaechstePruefung, setDatumNaechstePruefung] = useState("");

    const [benachrichtigungen, setBenachrichtigungen] = useState([]);

    const showModal = () => {
        setVisible(true);
    };

    const navigate = useNavigate();

    const handleOk = () => {
        setDeleteLoading(true);
        ApiWrapper.del('objekt-verwaltung-api', `/${props.activeAccountId}/objekte/${objektId}/delete`, {}).then((result) => {
            setDeleteLoading(false);
            setVisible(false);
            props.reloadData();
            navigate(`/objekte/`);
        }).catch(err => {
            console.log(err);
            setDeleteLoading(false);
            setVisible(false);
            notification['error']({
                message: 'Das Objekt konnte nicht gelöscht werden',
                description: err.message,
                placement: 'topRight',
                duration: 5
            });
        });
    };

    const handleCancel = () => {
        setVisible(false);
        setDeleteLoading(false);
    };

    const loadObjekt = async() => {
      setLoading(true);

        const accountData = props.accountData;
        if (accountData && accountData.objekte) {
            for (let objekt of accountData.objekte) {
                if(objekt.id == objektId){

                    if (objekt.benachrichtigungen !== undefined) {
                        setBenachrichtigungen(objekt.benachrichtigungen);
                    }

                    setObjektDetails(objekt);
                    setLoading(false);
                }
            }
        }

    };

    const loadElemente = async() => {
      setLoading(true);
      const elementListe = []

        const accountData = props.accountData;
        if (accountData && accountData.objekte) {

            for (let objekt of accountData.objekte)
            {
                if (objekt.id === objektId) {
                    setBuildings(objekt.buildings);
                    for (const building of objekt.buildings) {
                        for (const parts of building.parts) {
                            for (let element of parts.elemente)
                            {
                                element.buildingId = building.id;
                                elementListe.push(element);
                            }
                        }
                    }
                }
            }
        }

      setElemente(elementListe);
      setLoading(false);
    };

    const letztePruefungErmitteln = () => {

        let naechstePruefungen = [];
        for(const element of elemente) {
            if (Array.isArray(element.naechstePruefungen)) {
                for (const naechstePruefung of element.naechstePruefungen) {
                    naechstePruefungen.push(naechstePruefung);
                }
            }
        }

        if (naechstePruefungen.length > 0){
            naechstePruefungen = naechstePruefungen.filter(naechstePruefung => naechstePruefung.letztePruefung !== undefined);
            let letztePruefung = new Date(Math.max(...naechstePruefungen.map(e => new Date(e.letztePruefung))));
            let naechstePruefung = new Date(Math.min(...naechstePruefungen.map(e => new Date(e.naechstePruefung))));
            let momentLetztePruefung = moment(letztePruefung).format('DD.MM.YYYY');
            let momentNaechstePruefung = moment(naechstePruefung).format('DD.MM.YYYY');
            setDatumNaechstePruefung(momentNaechstePruefung);
            setDatumLetztePruefung(momentLetztePruefung);
        }
    };

    const renderPruefung = () => {
        if (datumNaechstePruefung === '' || datumLetztePruefung === ''){
          // Wenn eines der Felder leer ist -> Zeige diese nicht an.
        } else {
          return <React.Fragment>
            <Statistic title="Nächste Prüfungen" value={datumNaechstePruefung}/>
            <Statistic title="Letzte Prüfungen" value={datumLetztePruefung}/>
          </React.Fragment>
        }
    };

    useEffect(() => {
        setAnzahlElemente(elemente.length);
        letztePruefungErmitteln();
    }, [elemente]);

    useEffect(() => {
        loadElemente();
        loadObjekt();
    }, [props]);

    const renderActionsIfAdmin = () => {
        const actions = [];
        actions.push(<Button key="4"><Link to={`/objekt/${objektId}/editNotification`}>Benachrichtung bearbeiten</Link></Button>);
        /* Auch wenn User kein Admin ist: Zeige Link zur Seite "Benachrichtung bearbeiten" */

        if (isAdmin()) {
            actions.push(<Button key="3"><Link to={`/objekt/${objektId}/edit`}>Objekt bearbeiten</Link></Button>);
            actions.push(<Button key="2" onClick={()=>setVisible(true)}>Löschen</Button>);
            actions.push(<Button key="1" type="primary"><Link to={`/objekt/${objektId}/elemente/einrichten`}>Einrichten</Link></Button>);
        }

        return actions;
    };

    const columns = [{
      title: 'Anzahl Tage vor Ablauf',
      dataIndex: 'numberOfDaysBefore',
      key: 'numberOfDaysBefore',
    },
        {
          title: 'Medium',
          dataIndex: 'versandart',
          key: 'versandart',
        },
    {
      title: 'Empfänger',
      key: 'empfaengerListe',
      dataIndex: 'empfaengerListe',
      render: empfaenger => {
        empfaenger = empfaenger.split(',');
        const numTags = empfaenger.length;
        return (
          <React.Fragment>
            {empfaenger.map((tag, i) => {
              return (
                <React.Fragment>
                  <Tag color={'green'}>
                    {tag.toUpperCase()}
                  </Tag>
                  {numTags !== i + 1 && // Not last element
                  <React.Fragment>/ </React.Fragment>
                  }
                </React.Fragment>

              );
            })}
          </React.Fragment>
        );
      },
    },
  ];

    const renderElementTables = () => {
        const tabellen = [];

        const columns = [
            {
                title: 'Gebäudeteil',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: 'Etage',
                dataIndex: 'floor',
                key: 'floor',
                sorter: (a, b) => a.floor.localeCompare(b.floor),
            },
            {
                title: 'Nummer',
                dataIndex: 'number',
                key: 'number',
                sorter: (a, b) => a.number.localeCompare(b.number),
            }
        ];

        for (const [i, building] of buildings.entries()) {
            for (const [x, part] of building.parts.entries()) {
                part.key = x;
                for (const el of part.elemente) {
                    el.buildingId = building.id;
                }
            }

            if (building.parts.length > 0){
              // Nur wenn das Gebäude mindestens ein Element besitzt, wird es auch ausgegeben.
                const renderer = {
                    expandedRowRender: record => <ElementList elemente={record.elemente} loading={loading} activeAccountId={props.activeAccountId}
                                                              reloadElemente={() => loadElemente()}/>
                }

                tabellen.push(
                    <Panel header={building.name} key={i}>

                        <Table
                            columns={columns}
                            expandable={renderer}
                            dataSource={building.parts}
                        />


                    </Panel>

                );
            }
        }

        return tabellen;
    }

    return (
        <SiteLayout pageTitle="Überschrift" breadcumItems={[
            <Link to={`/uebersicht`}>Account</Link>,
            <Link to={`/objekte`}>Objektübersicht</Link>,
            `${objektDetails.titel}`
        ]}>

            <PageHeader
                ghost={false}
                title={objektDetails.titel}
                subTitle={objektDetails.street}
                extra={renderActionsIfAdmin()}
            ></PageHeader>
            <Card title="Details" bordered={true} style={{'textAlign': 'left', 'margin': '10px 0'}}
            extra={[
              <Button key="4">
                  <Link to={`/${getActiveAccountId()}/pruefungen?objektId=${objektId}`}>Prüfungen einsehen</Link>
              </Button>
            ]}
            >
                <Row gutter={16}>
                    <Col span={16}>
                        <Statistic title="Name" value={objektDetails.titel}/>
                        <br/>
                        <Statistic title="Beschreibung" value={objektDetails.beschreibung}/>
                    </Col>

                    <Col span={8}>
                        <Statistic title="Anzahl der überwachten Elemente" value={anzahlElemente}/>
                        {renderPruefung()}
                    </Col>
                </Row>
            </Card>

            <CollapsibleCard title="Benachrichtung" bordered={true} style={{'textAlign': 'left', 'margin': '10px 0'}}>
                <Table dataSource={benachrichtigungen} columns={columns} loading={loading} rowKey="id"/>
            </CollapsibleCard>

            <Card title="Elemente im Objekt" bordered={true} style={{'textAlign': 'left', 'margin': '10px 0'}}>
                <Collapse>
                    {renderElementTables()}
                </Collapse>

            </Card>

            <Modal
                title="Title"
                visible={visible}
                onOk={handleOk}
                confirmLoading={deleteLoading}
                onCancel={handleCancel}
            >
                <p>Soll das Objekt wirklich gelöscht werden?</p>
            </Modal>

        </SiteLayout>
    );
};

export default ObjectDetailsContainer;
