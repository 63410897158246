import React, {useEffect, useState} from 'react';
import ApiWrapper from '../Utils/ApiWrapper';

import SiteLayout from '../Components/Styled/SiteLayout';

import {Button, Checkbox, Form, Input, Select, Table} from 'antd';
import {AUTH_USER_ID} from "../Utils/constants";
import {isAdmin} from "../Utils/helpers";

const UsersContainer = (props) => {

    const [form] = Form.useForm();

    const [usersInGroup, setUsersInGroup] = useState([]);
    const [rechtOfObject, setRechtOfObject] = useState([]);
    const [dataSource, setDataSource] = useState([]);
    const [value, setValue] = useState('');
    const [loading, setLoading] = useState(true);
    const [selectedObject, setSelectedObject] = useState();

    const accountId = props.activeAccountId;
    const {Option} = Select;

    const userId = localStorage.getItem(AUTH_USER_ID);

    function onChangeObjekt(value) {
        console.log(`selected Object  - ${value}`);
        setSelectedObject(value);
        loadRechteForObjekt(value);
    }


    const handleCheckboxChangeFactory = (record, columnKey) => event => {
        record[columnKey] = event.target.checked;
        setRechtOfObject(rechtOfObject.map(object => object.id === record.id ? record : object))
    };

    const FilterByNameInput = (
        <Input
            placeholder="Benutzername"
            value={value}
            onChange={e => {
                const currValue = e.target.value;
                setValue(currValue);
                const filteredData = rechtOfObject.filter(entry =>
                    entry.name.includes(currValue)
                );
                setDataSource(filteredData);
            }}
        />
    );

    const columns = [
        {
            title: FilterByNameInput,
            dataIndex: 'name',
            key: 'name',
            width: '30%'
        }, {
            title: 'Lesen',
            dataIndex: 'lesen',
            key: 'lesen',
            align: 'center',
            render: (value, record, rowIndex) => (
                <Checkbox
                    checked={value}
                    onChange={handleCheckboxChangeFactory(record, "lesen")}
                />
            )
        }, {
            title: 'Schreiben',
            dataIndex: 'schreiben',
            key: 'schreiben',
            align: 'center',
            render: (value, record, rowIndex) => (
                <Checkbox
                    checked={value}
                    onChange={handleCheckboxChangeFactory(record, "schreiben")}
                />
            )
        }, {
            title: 'Verwalten',
            dataIndex: 'verwalten',
            key: 'verwalten',
            align: 'center',
            render: (value, record, rowIndex) => (
                <Checkbox
                    checked={value}
                    onChange={handleCheckboxChangeFactory(record, "verwalten")}
                />
            )
        }
    ];

    const loadUserInGroupFromCognito = (accountId) => {
        // Alle Nutzer aus der Cognitio Gruppe laden
        ApiWrapper.get('objekt-verwaltung-api', `/accounts/${accountId}/users/`, {}).then((result) => {
            console.log(result)

            const parsedBody = JSON.parse(result.body);
            console.log(parsedBody);
            const parsedBodyAsArray = Array.isArray(parsedBody) ? parsedBody : [parsedBody];
            setUsersInGroup(parsedBodyAsArray);

            setLoading(false);
        }).catch(err => {
            console.log(err);
        })
    }

    const loadRechteForObjekt = async (selectedObject) => {

        const rechteFromObject = [];

        if (selectedObject) {
            const accountData = props.accountData;
            if (accountData && accountData.objekte) {
                for (const object of accountData.objekte) {
                    if (selectedObject === object.id) {

                        for (const userInAccount of usersInGroup) {
                            let userWithRights = object.nutzerRechte.find(user => user.id === userInAccount.id);
                            if (!userWithRights) {
                                userWithRights = {
                                    id: userInAccount.id
                                };
                            }
                            if (!userWithRights || !userWithRights.lesen) {
                                userWithRights.lesen = false;
                            }
                            if (!userWithRights || !userWithRights.schreiben) {
                                userWithRights.schreiben = false;
                            }
                            if (!userWithRights || !userWithRights.verwalten) {
                                userWithRights.verwalten = false;
                            }
                            userWithRights.name = userInAccount.additionalAttributes.UserAttributes.find(att => att.Name === "email").Value;
                            console.log(userWithRights);
                            rechteFromObject.push(userWithRights);
                        }
                    }
                }
                setRechtOfObject(rechteFromObject);
            }
        }
    };

    const renderObjektDropdown = () => {

        if (usersInGroup.length >= '1') {

            const menuOptions = [];

            const accountData = props.accountData;
            if (accountData && accountData.objekte) {
                for (const object of accountData.objekte) {

                    const userWithRights = object.nutzerRechte.find(user => user.id === userId);
                    if ((userWithRights && userWithRights.verwalten === true) || isAdmin()) {
                        menuOptions.push(<Option key={object.id} value={object.id}>
                            {object.titel}
                        </Option>);
                    }
                }
            }

            return <Select
                showSearch
                label="Select Objekt"
                style={{width: 300}}
                placeholder="Objekt wählen"
                onChange={onChangeObjekt}
                dropdownMatchSelectWidth={true}
                filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
            >
                {menuOptions}
            </Select>;
        } else {
        }
    };

    const onFinish = async (values) => {
        await ApiWrapper.put('objekt-verwaltung-api', `/${props.activeAccountId}/objekt/${selectedObject}/berechtigungen`, rechtOfObject);
        await props.reloadData();
        console.log(rechtOfObject);
    };

    const renderObjektTabelle = () => {
        if (rechtOfObject) {
            return (
                <Form form={form} onFinish={onFinish}>
                    <Table dataSource={dataSource} columns={columns} loading={loading}/>
                    <Button type="primary" htmlType="submit" style={{marginLeft: 8, marginRight: 8}}>
                        Speichern
                    </Button>
                </Form>

            );
        } else {
            return null;
        }
    };


    useEffect(async () => {
        await loadUserInGroupFromCognito(accountId);
        console.log("1. Useeffect");
    }, []);

    useEffect(async () => {
        setDataSource(rechtOfObject);
        console.log("2. Useeffect");
    }, [rechtOfObject]);


    return (
        <SiteLayout pageTitle="Überschrift">
            <br/>
            {renderObjektDropdown()}
            <br/>
            <br/>
            {renderObjektTabelle()}
        </SiteLayout>
    );
};

export default UsersContainer;
