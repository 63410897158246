import React, {useState, useEffect} from 'react';
import ApiWrapper from '../Utils/ApiWrapper';

import SiteLayout from '../Components/Styled/SiteLayout';

import {Table, Space} from 'antd';
import {Link} from "react-router-dom";


const UserManagementContainer = (props) => {

    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            sorter: (a, b) => a.id.localeCompare(b.id)
        }, {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            sorter: (a, b) => a.email.localeCompare(b.email)
        },
        {
            title: 'Aktionen',
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    <Link to={`/admin/benutzer/${record.id}`}>Bearbeiten</Link>
                </Space>
            ),
        }
    ];

    const loadUserCognito = () => {
        // Alle Nutzer aus Cognitio laden

        ApiWrapper.get('objekt-verwaltung-api', `/users`, {}).then((result) => {
            console.log(result)
            const parsedBody = JSON.parse(result.body);
            console.log(parsedBody);
            const parsedUsers = parsedBody.Users;
            const parsedUsersAsArray = Array.isArray(parsedUsers) ? parsedUsers : [parsedUsers];

            const listOfUsers = parsedUsersAsArray.map(user => {
                return {
                    id: user.Attributes.find(att => att.Name === "sub").Value,
                    email: user.Attributes.find(att => att.Name === "email").Value,
                }
            });

            setUsers(listOfUsers);
            setLoading(false);

        }).catch(err => {
            console.log(err);
        })
    }

    useEffect(async () => {
        await loadUserCognito();
    }, []);

    return (
        <SiteLayout pageTitle="Überschrift">

            <Table dataSource={users} columns={columns} loading={loading}/>


        </SiteLayout>
    );
};

export default UserManagementContainer;
