import React, { useState, useEffect, useContext } from 'react';
import SiteLayout from '../Components/Styled/SiteLayout';
import {useNavigate, Link, useParams} from 'react-router-dom';


import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

import ApiWrapper from '../Utils/ApiWrapper';

import { Button, Form, Input, PageHeader, Select, notification } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import {getActiveAccountId} from "../Utils/helpers";

const { TextArea } = Input;
const {Option} = Select;

const ObjectEditContainer = (props) => {
  const [form] = Form.useForm();

  const navigate = useNavigate();
    const params = useParams();

  const [objektDetails, setObjektDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const objektId = params.objektId;


  const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16,}
  };

  const tailLayout = {
    wrapperCol: {
      offset: 8,
      span: 16,
    },
  };

  const loadObjekt = async() => {

    const accountData = props.accountData;
    if (accountData && accountData.objekte) {
      for (let objekt of accountData.objekte) {
        if(objekt.id == objektId){
          setObjektDetails(objekt);
        }
      }
    }

  };


   const onFinish = (values) => {
    ApiWrapper.put('objekt-verwaltung-api', `/${props.activeAccountId}/objekt/${objektId}`, values).then(async (result) => {
      console.log(result);
      notification.success({
                    message: 'Objekt bearbeitet!',
                    description: 'Das Objekt wurde erfolgreich bearbeitet!',
                    placement: 'topRight',
                    duration: 10
                });
      await props.reloadData();
      navigate(`/objekt/${objektId}`);
    }).catch(err => {
      console.log(err);
    });
    console.log('Success:', 'ok');
   };

  const onFinishFailed = (errorInfo) => {
    notification.error({
        message: 'Fehler!',
        description: 'Sie haben nicht alle Pflichtfelder ausgefüllt',
        placement: 'topRight',
        duration: 10
    });
    console.log('Failed:', errorInfo);
  };

  useEffect(async() => {
    setLoading(true);
    await loadObjekt();
  }, []);

  useEffect(async() => {
      if (objektDetails && objektDetails.titel) {
          form.setFieldsValue({
          titel:        `${objektDetails.titel}`,
          beschreibung: `${objektDetails.beschreibung}`,
          street:       `${objektDetails.street}`,
          plz:          `${objektDetails.plz}`,
          city:         `${objektDetails.city}`
          });
        setLoading(false);
      }
  },[objektDetails]);

  if (loading){
          return null;
      }

return (
    <SiteLayout pageTitle="Detailansicht Element" breadcumItems={[
    <Link to={`/uebersicht`}>Startseite</Link>,
    <Link to={`/objekte`}>Objektübersicht</Link>,
    <Link to={`/objekt/${objektId}`}>Objekt</Link>,
    'Bearbeiten'
    ]}>
      <PageHeader
      title={`Objekt bearbeiten: ${objektDetails.titel}`}
      ghost={false}
      >
    </PageHeader>
        <br/>
        <div style={{ width:"80%"}}>

          <Form form={form}
              {...layout}
              name="basic"
              initialValues={{ remember: false, benachrichtigungen: objektDetails.benachrichtigungen }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              labelAlign="left"
            >

              <br/>

              <Form.Item
                  label="Titel"
                  name="titel"
                  rules={[{required: true, message: 'Titel fehlt'}]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                  label="Beschreibung"
                  name="beschreibung"
              >
                <TextArea rows={4}/>
              </Form.Item>

              <Form.Item
                  label="Straße + Hausnummer"
                  name="street"
                  rules={[{required: true, message: 'Straße fehlt'}]}
              >
                <Input/>
              </Form.Item>

              <Form.Item
                  label="Postleitzahl"
                  name="plz"
                  rules={[{required: true, message: 'Postleitzahl fehlt'}]}
              >
                <Input/>
                </Form.Item>

              <Form.Item
                  label="Ort"
                  name="city"
                  rules={[{required: true, message: 'Ort fehlt'}]}
              >
                <Input/>
              </Form.Item>

              <Form.Item {...tailLayout}>

                <Form.List name="benachrichtigungen" style={{ display: 'none' }}>

                {(fields, { add, remove }) => (
                  <React.Fragment style={{ display: 'none' }}>
                    {fields.map(field => (
                      <React.Fragment key={field.key} style={{ display: 'none' }}>
                        <Form.Item
                          {...field}
                          style={{ display: 'none' }}
                          label="Tage bevor Fälligkeit"
                          name={[field.name, 'numberOfDaysBefore']}
                          fieldKey={[field.fieldKey, 'numberOfDaysBefore']}
                          rules={[{ required: true, message: 'Anzahl der Tage fehlt' }]}
                        >
                        <Input placeholder="XX Tage" />
                        </Form.Item>
                        <Form.Item label="Zustellungen" style={{ display: 'none' }}>
                          <Input.Group compact>
                            <Form.Item
                              style={{ display: 'none' }}
                              name={[field.name, 'versandart']}
                              noStyle
                              rules={[{ required: true, message: 'Versandart fehlt' }]}
                            >
                    <Select placeholder="Bitte wählen" className="select-after" style={{ display: 'none' }}>
                      <Option value="email">Email</Option>
                      <Option value="sms">SMS</Option>
                    </Select>
                            </Form.Item>
                            <Form.Item
                              style={{ display: 'none' }}
                              name={[field.name, 'empfaengerListe']}
                              noStyle
                              rules={[{ required: true, message: 'Empfänger fehlt' }]}
                            >
                              <Input style={{ width: '50%', display: 'none' }} placeholder="Hier Empfänger eintragen" />
                            </Form.Item>
                          </Input.Group>
                        </Form.Item>
                      </React.Fragment>
                    ))}
                  </React.Fragment>
                )}
              </Form.List>
                <Button key="4"><Link to={`/objekt/${objektId}/editNotification`}>Benachrichtung bearbeiten</Link></Button>
                <Button htmlType='button' style={{marginLeft: 8, marginRight:8,marginBottom:12}} >
                  <Link to={`/objekt/${objektId}`}>Abbrechen</Link>
                </Button>
                <Button type="primary" htmlType="submit" style={{marginLeft: 8, marginRight:8}}>
                  Speichern
                </Button>
              </Form.Item>
          </Form>
        </div>

    </SiteLayout>
  );
};


export default ObjectEditContainer;
