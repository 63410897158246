import React, {useState, useEffect} from 'react';

import {DatePicker, Cascader, Select, Button, Form, Input, Tooltip, Modal, AutoComplete} from 'antd';
import {MinusOutlined, PlusOutlined, InfoCircleOutlined, ExclamationCircleOutlined} from '@ant-design/icons';

import ApiWrapper from '../Utils/ApiWrapper';
import moment from 'moment';
import de_DE from "antd/lib/date-picker/locale/de_DE";
import {getActiveAccountId} from "../Utils/helpers";

const {Option} = Select;
const { confirm } = Modal;

const Element = ({form, objektId, element}) => {

    const [elementDetails, setElementDetails] = useState({});
    const [loading, setLoading] = useState(true);
    const [kategorie, setKategorie] = useState([]);
    const [pruefungsarten, setPruefungsarten] = useState([]);
    const [pruefungenToDisplay, setPruefungenToDisplay] = useState([]);

    const layout = {
        labelCol: {span: 8},
        wrapperCol: {span: 16},
    };

    const loadOptions = async () => {

        ApiWrapper.get('objekt-verwaltung-api', `/${getActiveAccountId()}/objekte/${objektId}/elemente/pruefungsarten`, {}).then((result) => {
            console.log(result);
            const parsedBody = JSON.parse(result.body);

            const parsedBodyAsArray = Array.isArray(parsedBody) ? parsedBody : [parsedBody];
            setPruefungsarten(parsedBodyAsArray);

            const tempOptions = [];

            for (const pruefungsart of parsedBodyAsArray) {
                const tempOpt = {
                    value: pruefungsart.id,
                    label: pruefungsart.titel
                };
                tempOptions.push(tempOpt);
            }
            console.log(tempOptions);
            setKategorie(tempOptions);
            return tempOptions;
        }).catch(err => {
            console.log(err);
        });
    };

    const loadElement = () => {
        if (element) {
            if (Array.isArray(element.naechstePruefungen)) {
                for (let naechstePruefungen of element.naechstePruefungen) {
                    naechstePruefungen.letztePruefung = moment(element.letztePruefung);//.format("DD.MM.YYYY");
                }
            } else {
                element.naechstePruefungen = [];
            }

            if (element.naechstePruefungen) {
                const prToDisplay = element.naechstePruefungen.filter(naechstePruefungen => !naechstePruefungen.preview)
                console.log(prToDisplay)
                setPruefungenToDisplay(prToDisplay)
            }
            setElementDetails(element);
        }
    };

    useEffect(async () => {
        setLoading(true);
        await loadOptions();
        loadElement();
        setLoading(false);
    }, [element]);

    function onChange(value) {
        console.log(value);

        const prArten = pruefungsarten.find(art => art.id === value);
        console.log(prArten)

        if (prArten && prArten.pruefungen) {

            const formData = form.getFieldsValue(true);

            if (formData.naechstePruefungen && formData.naechstePruefungen.length > 0 && formData.categoryId !== value) {
                console.log('wirklich tauschen?')
                confirm({
                    title: 'Vorhandene Prüfungen überschreiben?',
                    icon: <ExclamationCircleOutlined />,
                    content: 'Für diese Elementart gibt es vorgegebene Prüfungen. Sollen die vorhanden Prüfungen mit diesen Überschrieben werden?',
                    okText: 'Ja',
                    okType: 'danger',
                    cancelText: 'Nein',
                    onOk() {
                        console.log('OK');
                        formData.naechstePruefungen = prArten.pruefungen;
                        form.setFieldsValue(formData)
                    },
                    onCancel() {
                        console.log('Cancel');
                    },
                });
            } else {
                formData.naechstePruefungen = prArten.pruefungen;
                form.setFieldsValue(formData)
            }
        }
    }

    useEffect(async () => {

        if (kategorie.length >= 1 && elementDetails) {
            setLoading(true);
            form.setFieldsValue({
                titel: elementDetails.titel,
                categoryId: elementDetails.categoryId,
                beschreibung: elementDetails.beschreibung,
                geraeteId: elementDetails.geraeteId,
                naechstePruefungen: pruefungenToDisplay
            });

            if (elementDetails.categoryId && !elementDetails.titel) {
                onChange(elementDetails.categoryId);
            }

            setLoading(false);
        }
    }, [elementDetails, kategorie]);

    const renderBody = () => {
        if (loading) {
            return null;
        } else {

            return (
                <div style={{width: "80%"}}>
                    <Form form={form}
                          {...layout}
                          name="basic"
                          initialvalues={{remember: false, naechstePruefungen: pruefungenToDisplay}}
                          scrollToFirstError={true}
                          autoComplete="off"
                          labelAlign="left"
                    >

                        <br/>
                        <h2>Element bearbeiten: </h2>
                        <Form.Item
                            label="Element Kategorie"
                            name="categoryId"
                            rules={[{required: true, message: 'Element Kategorie fehlt'}]}
                        >
                            <Select
                                options={kategorie}
                                onSelect={onChange}
                                placeholder="Bitte auswählen"
                            />

                        </Form.Item>
                        <Form.Item
                            label="Titel"
                            name="titel"
                            rules={[{required: true, message: 'Titel fehlt'}]}
                        >
                            <Input/>
                        </Form.Item>

                        <Form.Item
                            label="Beschreibung"
                            name="beschreibung"
                            rules={[{required: true, message: 'Beschreibung fehlt'}]}
                        >
                            <Input/>
                        </Form.Item>

                        <Form.Item
                            label="Geräte Id"
                            name="geraeteId"
                            rules={[{required: false}]}
                        >
                            <Input
                                placeholder="Geräte Id"
                                suffix={
                                    <Tooltip title="Zusätzliche Information können hier gelistet werden">
                                        <InfoCircleOutlined style={{color: 'rgba(0,0,0,.45)'}}/>
                                    </Tooltip>
                                }
                            />
                        </Form.Item>
                        <Form.Item
                            label="Einbaudatum"
                            name="einbauDatum"
                            rules={[{required: false}]}
                        >
                            <DatePicker defaultValue={moment(elementDetails.einbauDatum)} placeholder="Datum" locale={de_DE}/>
                        </Form.Item>

                        <Form.List name="naechstePruefungen">

                            {(fields, {add, remove}) => (
                                <React.Fragment>
                                    {fields.map(field => (
                                        <React.Fragment key={field.key}>
                                            <hr/>
                                            <h2>Prüfung bearbeiten </h2>
                                            <Form.Item
                                                {...field}
                                                name={[field.name, 'id']}
                                                fieldKey={[field.fieldKey, 'id']}
                                            >
                                                <Input type="hidden"/>
                                            </Form.Item>
                                            <Form.Item
                                                {...field}
                                                label="Titel"
                                                name={[field.name, 'titel']}
                                                fieldKey={[field.fieldKey, 'titel']}
                                                rules={[{required: true, message: 'Titel fehlt'}]}
                                            >
                                                <Input placeholder="Titel"/>
                                            </Form.Item>
                                            <Form.Item
                                                {...field}
                                                label="Prüfung von"
                                                name={[field.name, 'pruefungVon']}
                                                fieldKey={[field.fieldKey, 'pruefungVon']}
                                                rules={[{required: true, message: 'Prüfer fehlt'}]}
                                            >
                                                <Select
                                                    placeholder="Prüfung von"
                                                >
                                                    <Option value="jeder">Jeder</Option>
                                                    <Option value="sv">Sachverständiger (SV)</Option>
                                                    <Option value="sk">Sachkundiger (SK)</Option>
                                                    <Option value="bp">Befähigte Person (bP)</Option>
                                                    <Option value="ef">Elektrofachkraft</Option>
                                                    <Option value="vu">Versicherer</Option>
                                                    <Option value="fb">Fachbetrieb</Option>
                                                    <Option value="he">Hersteller</Option>
                                                    <Option value="uv">Unternehmer, Verantwortlicher vor Ort</Option>
                                                    <Option value="ep">Elektrotechnisch unterwiesene Person</Option>
                                                </Select>
                                            </Form.Item>
                                            <Form.Item label="Interval">
                                                <Input.Group compact>
                                                    <Form.Item
                                                        name={[field.name, 'interval', 'art']}
                                                        noStyle
                                                        rules={[{required: true, message: 'Art fehlt'}]}
                                                    >
                                                        <Select placeholder="Bitte wählen" className="select-after">
                                                            <Option value="pruefung">Prüfung alle</Option>
                                                            <Option value="tausch">Austausch alle</Option>
                                                        </Select>
                                                    </Form.Item>
                                                    <Form.Item
                                                        name={[field.name, 'interval', 'wert']}
                                                        noStyle
                                                        rules={[{required: true, message: 'Anzahl fehlt'}]}
                                                    >
                                                        <Input style={{width: '50%'}} placeholder="XXX"/>
                                                    </Form.Item>
                                                    <Form.Item
                                                        name={[field.name, 'interval', 'einheit']}
                                                        noStyle
                                                        rules={[{required: true, message: 'Einheit fehlt'}]}
                                                    >
                                                        <Select initialvalues="jahre" className="select-after">
                                                            <Option value="tage">Tage</Option>
                                                            <Option value="monate">Monate</Option>
                                                            <Option value="jahre">Jahre</Option>
                                                        </Select>
                                                    </Form.Item>
                                                </Input.Group>
                                            </Form.Item>
                                            <Form.Item
                                                {...field}
                                                label="Letzte Prüfung"
                                                name={[field.name, 'letztePruefung']}
                                                fieldKey={[field.fieldKey, 'letztePruefung']}
                                                rules={[{required: false}]}
                                            >
                                                <DatePicker locale={de_DE} placeholder="Datum"/>
                                            </Form.Item>
                                            <Form.Item
                                                {...field}
                                                label="Beschreibung"
                                                name={[field.name, 'beschreibung']}
                                                fieldKey={[field.fieldKey, 'beschreibung']}
                                                rules={[{required: false}]}
                                            >
                                                <Input.TextArea/>
                                            </Form.Item>
                                            <Form.Item>
                                                <Button type="dashed" style={{ width: '60%' }} onClick={() => remove(field.name)} block
                                                        icon={<MinusOutlined/>}>
                                                    Prüfung löschen
                                                </Button>
                                            </Form.Item>
                                        </React.Fragment>
                                    ))}
                                    <hr/>
                                    <Form.Item>
                                        <Button type="dashed" style={{ width: '60%' }} onClick={() => add()} block icon={<PlusOutlined/>}>
                                            Prüfung hinzufügen
                                        </Button>
                                    </Form.Item>
                                </React.Fragment>
                            )}
                        </Form.List>
                    </Form>
                </div>
            );
        }
    }

    return (
        renderBody()
    );
};
export default Element;
