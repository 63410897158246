import React from 'react';
import {Navigate, useLocation} from 'react-router-dom';
import NoAccountsContainer from '../Containers/NoAccountsContainer';

/** Helpers */
import {validateToken, hasUserAccounts, hasUserActiveAccount, isAdmin} from '../Utils/helpers';

/** Constants */
import {AUTH_USER_TOKEN_KEY, AUTH_USER_ACTIVE_ACCOUNT} from '../Utils/constants';
import ContextWrapper from "./ContextWrapper";

const PrivateRoute = ({component: Component, onlyAdmin}) => {

    const location = useLocation();

    const checkUserAuth = validateToken(localStorage.getItem(AUTH_USER_TOKEN_KEY));

    const activeAccountId = localStorage.getItem(AUTH_USER_ACTIVE_ACCOUNT);

    if (!checkUserAuth || (onlyAdmin && !isAdmin())) {
        return (<Navigate
            to={{
                pathname: '/'
            }}
        />);
    } else if (!hasUserAccounts()) {
        return (
            <NoAccountsContainer/>
        );
    } else if (!hasUserActiveAccount() && !location.pathname.includes('/account/wechseln')) {
        console.log(location.pathname)
        // Abfrage auf path, damit es keine Endlos-Redirects gibt
        //return (<ChooseActiveAccountContainer />);
        return (<Navigate
            to={{
                pathname: '/account/wechseln'
            }}
        />);
    } else {
        return (
            <ContextWrapper component={Component}  activeAccountId={activeAccountId}/>
        );
    }

};

export default PrivateRoute;
