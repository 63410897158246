import React, {useState, useEffect, useContext, useRef} from 'react';
import {InputNumber, Input, Form, DatePicker, Mentions} from 'antd';
import moment from 'moment';
import EditableContext from '../Contexts/EditableContext';

const {Option} = Mentions;

const dateFormat = 'DD.MM.YYYY';

const EditableCell = ({
                          editing,
                          dataIndex,
                          title,
                          inputType,
                          possibleInput = [],
                          placeholder,
                          record,
                          index,
                          children,
                          handleSave,
                          enableEditOnClick,
                          notRequired,
                          ...restProps
                      }) => {

    const form = useContext(EditableContext);
    const inputRef = useRef(null);
    const [isEditing, setIsEditing] = useState(editing);

    const save = async () => {
        try {
            const values = await form.validateFields();

            toggleEdit();
            handleSave({...record, ...values});
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    };

    const getDataForKeyArray = (data, keyArray) => {

        if (Array.isArray(dataIndex)) {
            let tempData = data;
            for (const key of keyArray) {
                tempData = tempData[key];
            }
            return tempData;
        } else {
            return record[dataIndex];
        }


    }

    const toggleEdit = () => {
        setIsEditing(!isEditing);
        if (form) {
            form.setFieldsValue({
                [dataIndex]: getDataForKeyArray(record, dataIndex)
            });
        }

    };

    useEffect(() => {
        setIsEditing(editing);
    }, [editing]);

    useEffect(() => {
        if (isEditing && inputRef.current) {
            inputRef.current.focus();
        }
    }, [isEditing]);

    const renderInputElement = () => {
        if (inputType === 'date') {
            return (<DatePicker ref={inputRef} defaultValue={moment('2015/01/01', dateFormat)} format={dateFormat}/>)
        } else if (inputType === 'number') {
            return (<InputNumber ref={inputRef}/>)
        } else if (inputType === 'mention') {
            return (
                <Mentions ref={inputRef} rows={1} placeholder={placeholder} onBlur={save}
                          defaultValue={getDataForKeyArray(record, dataIndex)}>
                    {possibleInput.map(item => {
                        return (<Option value={item}>{item}</Option>)
                    })}
                </Mentions>
            )
        } else {
            return (<Input ref={inputRef} onBlur={save} placeholder={placeholder}/>);
        }
    }

    const renderChildValue = () => {

        if (enableEditOnClick) {
            return (<div className="editable-cell-value-wrap" onClick={toggleEdit}>
                {children}
            </div>);
        } else {
            return (children);
        }


    }

    return (
        <td {...restProps}>
            {isEditing ? (
                <Form.Item
                    name={dataIndex}
                    style={{
                        margin: 0,
                    }}
                    rules={notRequired ? [] : [
                        {
                            required: true,
                            message: `Bitte fülle ${title}!`,
                        },
                    ]}
                >
                    {
                        renderInputElement()
                    }
                </Form.Item>
            ) : renderChildValue()}
        </td>
    );
};


export default EditableCell;
