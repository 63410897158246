import React, { useState, useEffect, useContext } from 'react';
import SiteLayout from '../Components/Styled/SiteLayout';
import {useNavigate, Link, useParams} from 'react-router-dom';


import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

import ApiWrapper from '../Utils/ApiWrapper';

import { Button, Form, Input, PageHeader, Select, notification } from 'antd';
import {PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';
import {getActiveAccountId} from "../Utils/helpers";

const { TextArea } = Input;
const {Option} = Select;

const NotificationUserEditContainer = (props) => {
  const [form] = Form.useForm();

  const navigate = useNavigate();
    const params = useParams();

  const [objektDetails, setObjektDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const objektId = params.objektId;


  const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16,}
  };

  const tailLayout = {
    wrapperCol: {
      offset: 8,
      span: 16,
    },
  };

  const loadObjekt = async() => {

    const accountData = props.accountData;
    if (accountData && accountData.objekte) {
      for (let objekt of accountData.objekte) {
        if(objekt.id == objektId){
          setObjektDetails(objekt);
          setLoading(false);
        }
      }
    }

  };

   const onFinish = (values) => {
     values.beschreibung = objektDetails.beschreibung;
     values.city = objektDetails.city;
     values.plz = objektDetails.plz;
     values.street = objektDetails.street;
     values.titel = objektDetails.titel;

     // ****************************
     // Eigentlich die falsche Stelle für eine Validierung?
     //******************************

     // Validierung Für SMS und MAIL - mit anschließender Fehlermeldung und Abbruch
     for (let benachrichtigung of values.benachrichtigungen){
       if (benachrichtigung.versandart=='sms'){
         if(!/^([+]{0,1}[0-9\s]{7,18})$/.test(benachrichtigung.empfaengerListe)){
           notification.error({
               message: 'Fehler!',
               description: 'Die Nummer darf nur aus Zahlen bestehen. Die Ländervorwahl optional mit einem führenden "+" angeben.',
               placement: 'topRight',
               duration: 10
           });
           return;
         }
       }
       if (benachrichtigung.versandart=='email'){
         if(!/^([A-Za-z0-9-._+]{3,30}[@]{1,1}[A-Za-z0-9-_+]{3,30}[.]{1,1}[A-Za-z0-9]{2,3})$/.test(benachrichtigung.empfaengerListe)){
           notification.error({
               message: 'Fehler!',
               description: 'Die angegebene E-Mail Adresse ist nicht gültig',
               placement: 'topRight',
               duration: 10
           });
           return;
         }
       }
     }

    ApiWrapper.put('objekt-verwaltung-api', `/${props.activeAccountId}/objekt/${objektId}`, values).then(async (result) => {
      console.log(result);
      notification.success({
                    message: 'Benachrichtung bearbeitet!',
                    description: 'Die Benachrichtung wurde erfolgreich bearbeitet!',
                    placement: 'topRight',
                    duration: 10
                });
      await props.reloadData();
      navigate(`/objekt/${objektId}`);
    }).catch(err => {
      console.log(err);
    });
    console.log('Success:', 'ok');
   };

  const onFinishFailed = (errorInfo) => {
    notification.error({
        message: 'Fehler!',
        description: 'Sie haben nicht alle Pflichtfelder ausgefüllt',
        placement: 'topRight',
        duration: 10
    });
    console.log('Failed:', errorInfo);
  };

  useEffect(async() => {
    setLoading(true);
    await loadObjekt();
  }, []);

  if (loading){
          return null;
      }

return (
    <SiteLayout pageTitle="Detailansicht Element" breadcumItems={[
    <Link to={`/uebersicht`}>Startseite</Link>,
    <Link to={`/objekte`}>Objektübersicht</Link>,
    <Link to={`/objekt/${objektId}`}>Objekt</Link>,
    'Benachrichtung bearbeiten'
    ]}>
      <PageHeader
          title={`Benachrichtung bearbeiten: ${objektDetails.titel}`}
          ghost={false}
      >
      </PageHeader>
        <br/>
        <div style={{ width:"80%"}}>

          <Form form={form}
              {...layout}
              name="basic"
              initialValues={{ remember: false, benachrichtigungen: objektDetails.benachrichtigungen }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              labelAlign="left"
            >

            <Form.Item {...tailLayout}>

                <Form.List name="benachrichtigungen">

                {(fields, { add, remove }) => (
                  <React.Fragment>
                    {fields.map(field => (
                      <React.Fragment key={field.key}>
                      <hr/>
                        <h2>Benachrichtung bearbeiten </h2>
                        <Form.Item
                          {...field}
                          label="Tage bevor Fälligkeit"
                          name={[field.name, 'numberOfDaysBefore']}
                          fieldKey={[field.fieldKey, 'numberOfDaysBefore']}
                          rules={[{ required: true, message: 'Anzahl der Tage fehlt' }]}
                        >
                        <Input placeholder="XX Tage" />
                        </Form.Item>
                        <Form.Item label="Zustellungen">
                          <Input.Group compact>
                            <Form.Item
                              name={[field.name, 'versandart']}
                              noStyle
                              rules={[{ required: true, message: 'Versandart fehlt' }]}
                            >
                                <Select placeholder="Bitte wählen" className="select-after">
                                  <Option value="email">Email</Option>
                                  <Option value="sms">SMS</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item
                              name={[field.name, 'empfaengerListe']}
                              noStyle
                              rules={[{ required: true, message: 'Empfänger fehlt'}]}
                            >
                              <Input style={{ width: '50%' }} placeholder="E-Mail Adresse eintragen" />
                            </Form.Item>
                          </Input.Group>
                        </Form.Item>
                      <Form.Item>
                      <Button type="dashed" onClick={() => remove(field.name)} block icon={<MinusCircleOutlined />} style={{width:'50%'}}>
                        Benachrichtung löschen
                      </Button>
                    </Form.Item>
                      </React.Fragment>
                    ))}
                    <hr/>
                    <Form.Item>
                      <Button type="dashed" onClick={() => add()} block icon={<PlusCircleOutlined />} style={{width:'50%'}}>
                        Benachrichtung hinzufügen
                      </Button>
                    </Form.Item>
                  </React.Fragment>
                )}
              </Form.List>
                <Button htmlType='button' style={{marginLeft: 8, marginRight:8,marginBottom:12}} >
                  <Link to={`/objekt/${objektId}`}>Abbrechen</Link>
                </Button>
                <Button type="primary" htmlType="submit" style={{marginLeft: 8, marginRight:8}}>
                  Speichern
                </Button>
              </Form.Item>
          </Form>
        </div>

    </SiteLayout>
  );
};


export default NotificationUserEditContainer;
