import React, { useState, useEffect } from 'react';
import SiteLayout from '../Components/Styled/SiteLayout';
import { useNavigate, Link } from 'react-router-dom';


import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

import ApiWrapper from '../Utils/ApiWrapper';

import { Avatar, Modal, Button, Form, Input, List, Space, PageHeader, Steps, Divider, notification, Checkbox, Table} from 'antd';

const { TextArea } = Input;
const { Step } = Steps;

const ObjectCreateContainer = (props) => {

  const [usersInGroup, setUsersInGroup] = useState([]);
  const [rechtOfObject, setRechtOfObject]= useState([]);
  const [checkboxState, setCheckboxState]= useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [value, setValue] = useState('');
  const [loading, setLoading] = useState(true);
  const [selectedObject, setSelectedObject] = useState();
  const [current, setCurrent] = useState(0);

  const accountId = props.activeAccountId;

  const [form] = Form.useForm();
  const navigate = useNavigate();

  const loadUserInGroupFromCognito = (accountId) => {
    // Alle Nutzer aus der Cognitio Gruppe laden
    ApiWrapper.get('objekt-verwaltung-api', `/accounts/${accountId}/users/`, {}).then((result) => {
      console.log(result)
      const parsedBody = JSON.parse(result.body);
      console.log(parsedBody);
      const parsedBodyAsArray = Array.isArray(parsedBody) ? parsedBody : [parsedBody];
      setUsersInGroup(parsedBodyAsArray);

      setLoading(false);

    }).catch(err => {
      console.log(err);
    })
  }

  const loadRechteForObjekt = async(selectedObject) => {

    const rechteFromObject = [];

    if (selectedObject){
                  console.log(selectedObject);
                  console.log(usersInGroup);
                    for (const userInAccount of usersInGroup) {
                        let userWithRights = {
                                id: userInAccount.id,
                                lesen: false,
                                schreiben: false,
                                verwalten:false
                        };
                        userWithRights.name = userInAccount.additionalAttributes.UserAttributes.find(att => att.Name === "email").Value;
                        console.log(userWithRights);
                        rechteFromObject.push(userWithRights);
                    }

            setRechtOfObject(rechteFromObject);
    }
};

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16,}
  };

  const tailLayout = {
    wrapperCol: {
      offset: 8,
      span: 16,
    },
  };

  const FilterByNameInput = (
    <Input
      placeholder="Benutzername"
      value={value}
      onChange={e => {
        const currValue = e.target.value;
        setValue(currValue);
        const filteredData = rechtOfObject.filter(entry =>
          entry.name.includes(currValue)
        );
        setDataSource(filteredData);
      }}
    />
  );

  const handleCheckboxChangeFactory = (record, columnKey) => event => {
      record[columnKey] = event.target.checked;
      setRechtOfObject(rechtOfObject.map(object => object.id === record.id ? record : object))
  };

  const onFinishStepOne = (values) => {
    ApiWrapper.post('objekt-verwaltung-api', `/${props.activeAccountId}/objekte/`, values).then(async (result) => {
      const body = JSON.parse(result.body);
      notification.success(notificationObjektAngelegt);
      setSelectedObject(body);
      console.log(body.id);
      next();
    }).catch(err => {
      console.log(err);
    });
  };

  const onFinishStepTwo = (values) => {

      ApiWrapper.put('objekt-verwaltung-api', `/${props.activeAccountId}/objekt/${selectedObject.id}/berechtigungen`, rechtOfObject).then(async (result) => {
        const body = result.body;
        notification.success(notificationBerechtigungVergeben);
        navigate(`/objekte`);
      }).catch(err => {
        console.log(err);
      });
    };

  const onFinishFailed = (errorInfo) => {
    notification.error({
        message: 'Fehler!',
        description: 'Sie haben nicht alle Pflichtfelder ausgefüllt',
        placement: 'topRight',
        duration: 10
    });
    console.log('Failed:', errorInfo);
  };

  const firstStep = () => {
    return (
        <div style={{ width:"80%"}}>
          <Form
              {...layout}
              name="basic"
              initialValues={{remember: false}}
              onFinish={onFinishStepOne}
              onFinishFailed={onFinishFailed}
              labelAlign="left"
            >

              <Form.Item
                  label="Titel"
                  name="titel"
                  rules={[{required: true, message: 'Titel fehlt'}]}
              >
                <Input/>
              </Form.Item>

              <Form.Item
                  label="Beschreibung"
                  name="beschreibung"
              >
                <TextArea rows={4}/>
              </Form.Item>

              <Form.Item
                  label="Straße + Hausnummer"
                  name="street"
                  rules={[{required: true, message: 'Straße fehlt'}]}
              >
                <Input/>
              </Form.Item>

              <Form.Item
                  label="Postleitzahl"
                  name="plz"
                  rules={[{required: true, message: 'Postleitzahl fehlt'}]}
              >
                <Input/>
                </Form.Item>

              <Form.Item
                  label="Ort"
                  name="city"
                  rules={[{required: true, message: 'Ort fehlt'}]}
              >
                <Input/>
              </Form.Item>
              <Form.Item {...tailLayout}>
                <Button htmlType='button' style={{marginLeft: 8, marginRight:8,marginBottom:12}}>
                  <a href="/objekte/">Abbrechen</a>
                </Button>

                <Button type="primary" htmlType="submit" style={{marginLeft: 8, marginRight:8}}>
                  Objekt anlegen
                </Button>
              </Form.Item>
          </Form>
        </div>
    )
  }

  const secondStep = () => {
    if(rechtOfObject){
      return (
          <Form
            form={form}
            onFinish={onFinishStepTwo}
            onFinishFailed={onFinishFailed}
          >
              <Table dataSource={dataSource} columns={columns} loading={loading}/>
              <br/>
              <Button type="primary" htmlType="submit" style={{marginLeft: 8, marginRight:8}}>
                  Speichern
              </Button>
          </Form>
      );
  } else{ return }




  }

  useEffect(async() => {
    loadUserInGroupFromCognito(accountId);
  }, []);

  useEffect(async() => {
    loadRechteForObjekt(selectedObject);
  }, [selectedObject]);

  useEffect(async() => {
    setDataSource(rechtOfObject);
  }, [rechtOfObject]);



  const notificationObjektAngelegt = {
    message: 'Objekt angelegt!',
    description: 'Das Objekt wurde erfolgreich angelegt.',
    placement: 'topRight',
    duration: 5
  };

  const notificationBerechtigungVergeben = {
    message: 'Berechtigung vergeben!',
    description: 'Die Berechtigungen für das Objekt wurden vergeben',
    placement: 'topRight',
    duration: 5
  };

  const columns = [
      {
          title: FilterByNameInput,
          dataIndex: 'name',
          key: 'name',
          width: '30%'
      },{
          title: 'Lesen',
          dataIndex: 'lesen',
          key: 'lesen',
          align: 'center',
          render: (value, record, rowIndex) => (
            <Checkbox
              checked={value}
              onChange={handleCheckboxChangeFactory(record, "lesen")}
            />
          )
      },{
          title: 'Schreiben',
          dataIndex: 'schreiben',
          key: 'schreiben',
          align: 'center',
          render: (value, record, rowIndex) => (
            <Checkbox
              checked={value}
              onChange={handleCheckboxChangeFactory(record, "schreiben")}
            />
          )
      },{
          title: 'Verwalten',
          dataIndex: 'verwalten',
          key: 'verwalten',
          align: 'center',
          render: (value, record, rowIndex) => (
            <Checkbox
              checked={value}
              onChange={handleCheckboxChangeFactory(record, "verwalten")}
            />
          )
      }
  ];

  const steps = [{
          title: 'Objekt erstellen',
          content: firstStep(),
      },
      {
          title: 'Berechtigungen vergeben',
          content: secondStep(),
      },
  ];

  return (
    <SiteLayout pageTitle="Detailansicht Element" breadcumItems={[
    <Link to={`/uebersicht`}>Startseite</Link>,
    <Link to={`/objekte`}>Objektübersicht</Link>,
    'Objekt erstellen'
    ]}>
      <div style={{width:'60%'}} >
        <Steps current={current}>
            {steps.map(item => (
                <Step key={item.title} title={item.title}/>
            ))}
        </Steps>
      </div>
      <Divider/>
      <br/>

      <div className="steps-content">{steps[current].content}</div>

    </SiteLayout>
  );
};


export default ObjectCreateContainer;
