import React, {useState, useEffect} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {Select, Form, PageHeader, Input, Modal, Button, notification} from 'antd';
import {MinusOutlined, PlusOutlined, InfoCircleOutlined} from '@ant-design/icons';

import ApiWrapper from '../Utils/ApiWrapper';
import SiteLayout from '../Components/Styled/SiteLayout';

import moment from 'moment';
import {getActiveAccountId, translatePruefer} from "../Utils/helpers";


const PruefungsDetailContainer = (props) => {
    const [form] = Form.useForm();
    const {TextArea} = Input;

    const params = useParams();

    const [pruefung, setPruefung] = useState({});
    const [loading, setLoading] = useState(true);
    const objektId = params.objektId;
    const buildingId = params.buildingId;
    const buildingpartId = params.buildingpartId;
    const elementId = params.elementId;
    const pruefungId = params.pruefungsId;

    let navigate = useNavigate();

    const layout = {
        labelCol: {span: 8},
        wrapperCol: {span: 16},
    };


    const loadPruefung = () => {

        const accountData = props.accountData;
        if (accountData && accountData.objekte) {
            for (let objekt of accountData.objekte) {
                if (objekt.id === objektId) {
                    for (const building of objekt.buildings) {
                        for (const parts of building.parts) {
                            let objektNameTMP = objekt.titel;
                            for (let element of parts.elemente) {
                                if (element.id === elementId) {
                                    let elementNameTMP = element.titel;
                                    if (Array.isArray(element.vergangenePruefungen)) {
                                        for (let vergangenePruefung of element.vergangenePruefungen) {
                                            if (vergangenePruefung.id === pruefungId) {
                                                vergangenePruefung.objektTitel = objektNameTMP;
                                                vergangenePruefung.objekt = objektId;
                                                vergangenePruefung.elementTitel = elementNameTMP;
                                                vergangenePruefung.elementId = elementId;
                                                vergangenePruefung.resolvedAtDisplay = moment(vergangenePruefung.resolvedAt).format('DD.MM.YYYY');
                                                vergangenePruefung.updatedAt = moment(vergangenePruefung.updatedAt).format('DD.MM.YYYY');
                                                setPruefung(vergangenePruefung);
                                                setLoading(false);
                                                console.log(vergangenePruefung);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    };

    const onFinish = (values) => {
        let valuesEdited = {};
        if (values.commentAdded != '' || values.commentAdded != 'undefined') {
            valuesEdited.kommentar = `${pruefung.comment} ${values.commentAdded}`
        }

        valuesEdited.id = pruefungId;
        valuesEdited.elementId = pruefung.elementId;
        console.log(valuesEdited);

        ApiWrapper.put('objekt-verwaltung-api', `/${props.activeAccountId}/objekte/${objektId}/${elementId}/pruefung/${pruefungId}`, valuesEdited).then(async (result) => {
            notification.success({
                message: 'Prüfung ergänzt!',
                description: 'Die Prüfung wurde erfolgreich ergänzt!',
                placement: 'topRight',
                duration: 5
            });
            await props.reloadData();
            //navigate(`/${objektId}/vergangenePruefungen`);

        }).catch(err => {
            notification.error({
                message: 'Fehler!',
                description: 'Die Prüfung konnte nicht gespeichert werden. ',
                placement: 'topRight',
                duration: 5
            });
            console.log(err);
        });
    };


    const onFinishFailed = (errorInfo) => {
        notification.error({
            message: 'Fehler!',
            description: 'Sie haben nicht alle Pflichtfelder ausgefüllt.',
            placement: 'topRight',
            duration: 10
        });
        console.log('Failed:', errorInfo);
    };

    const renderKommentar = () => {
        if (pruefung.comment === "" || pruefung.comment === "undefined" || !pruefung.comment) {
            const commentJSX = <Form.Item label="Prüfkommentar anlegen" name="commentAdded">
                <TextArea rows={4}/>
            </Form.Item>
            return commentJSX;
        } else {
            var commentJSX = <div>
                <Form.Item label="Bisheriger Prüfkommentar" name="comment">
                    <h4>{`${pruefung.comment}`}</h4>
                </Form.Item>
                <Form.Item label="Prüfkommentar ergänzen" name="commentAdded">
                    <TextArea rows={4}/>
                </Form.Item>
            </div>
            return commentJSX;
        }
    }

    const renderBeschreibung = () => {
        if (pruefung.beschreibung === "" || pruefung.beschreibung === "undefined" || !pruefung.beschreibung) {
            return
        } else {
            return (
                <Form.Item label="Beschreibung" name="beschreibung">
                    <h4>{`${pruefung.beschreibung}`}</h4>
                </Form.Item>
            )
        }
    }

    const renderArt = () => {
        const text = pruefung.art === "pruefung" ? "Überprüfung" : "Austausch";
        return (
            <Form.Item label="Art der Prüfung" name="comment">
                <h4>{text}</h4>
            </Form.Item>
        );
    }

    const renderNotwendigerPruefer = () => {
        if (pruefung.pruefungVon === "" || pruefung.pruefungVon === "undefined" || !pruefung.pruefungVon) {
            return;
        } else {
            return <Form.Item label="Notwendiger Prüfer" name="">
                <h4>{`${translatePruefer(pruefung.pruefungVon)}`}</h4>
            </Form.Item>;
        }
    };

    const downloadFile = async (attachmentId) => {
        await ApiWrapper.download('objekt-verwaltung-api', `/${getActiveAccountId()}/objekte/${objektId}/download/${attachmentId}`)
    }

    const renderAttachments = () => {

        if (!pruefung.attachments || !Array.isArray(pruefung.attachments)) {
            return (<p>Keine Anhänge vorhanden</p>)
        }

        const attachments = [];

        for (const att of pruefung.attachments) {
            attachments.push(<Link to={""} onClick={() => downloadFile(att.id)}>-> {att.name}</Link>);
        }

        return attachments;

    }

    const renderPage = () => {
        if (loading === false) {
            var renderPageJSX =
                <div style={{width: "80%"}}>
                    <Form form={form}
                          {...layout}
                          name="basic"
                          onFinish={onFinish}
                          onFinishFailed={onFinishFailed}
                          scrollToFirstError={true}
                          autoComplete="off"
                          labelAlign="left"
                    >
                        <div style={{textAlign: 'left'}}>
                            <Form.Item
                                label="Titel"
                                name="titel"
                            >
                                <h3>{`${pruefung.titel}`}</h3>
                            </Form.Item>

                            {renderBeschreibung()}

                            <Form.Item
                                label="Erledigt am"
                                name="resolvedAt"
                            >
                                <h4>{`${pruefung.resolvedAtDisplay}`}</h4>
                            </Form.Item>

                            <Form.Item
                                label="Zuletzt bearbeitet"
                                name="updatedAt"
                            >
                                <h4>{`${pruefung.updatedAt}`}</h4>
                            </Form.Item>

                            {renderArt()}

                            {renderNotwendigerPruefer()}

                            <Form.Item
                                label="Geprüft durch"
                                name="resolvedFrom"
                            >
                                <h4>{`${pruefung.resolvedFrom}`}</h4>
                            </Form.Item>

                            <Form.Item
                                label="Anhänge">
                                {renderAttachments()}
                            </Form.Item>

                            {renderKommentar()}

                            <Button htmlType='button' style={{marginLeft: 8, marginRight: 8, marginBottom: 12}}>
                                <Link to={`/objekt/${objektId}/building/${buildingId}/part/${buildingpartId}/element/${elementId}/details`}>Abbrechen</Link>
                            </Button>
                            <Button type="primary" htmlType="submit" style={{marginLeft: 8, marginRight: 8}}>
                                Speichern
                            </Button>
                        </div>
                    </Form>
                </div>
            return renderPageJSX
        }
        ;
    };

    useEffect(async () => {
        setLoading(true);
        loadPruefung();
    }, [props.accountData]);

    return (
        <SiteLayout pageTitle="Element Bearbeiten" breadcumItems={[
            <Link to={`/objekte`}>Account</Link>,
            <Link to={`/objekte`}>Objektübersicht</Link>,
            <Link to={`/objekt/${objektId}`}>Objekt</Link>,
            <Link to={`/objekt/${objektId}/building/${buildingId}/part/${buildingpartId}/element/${elementId}/details`}>Element</Link>,
            `Prüfungsdetails`
        ]}>

            <PageHeader
                ghost={false}
                title={`${pruefung.objektTitel} - Element: ${pruefung.elementTitel}`}
            ></PageHeader>

            <br/>

            {renderPage()}

        </SiteLayout>
    );
};
export default PruefungsDetailContainer;
