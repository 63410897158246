import React, { useState } from 'react';
import { Table, Tag, Space, Modal } from 'antd';
import ApiWrapper from '../../Utils/ApiWrapper';
import {Link, useParams} from 'react-router-dom';

const ElementList = (props) => {
  const params = useParams();

  const objektId = params.objektId;

  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [elementToDelete, setElementToDelete] = useState({});

  const deleteElementFromDb = (elementId) => {
    ApiWrapper.del('objekt-verwaltung-api', `/${props.activeAccountId}/objekte/${objektId}/elemente/${elementId}/delete`, {}).then((result) => {
      console.log(result);
      setVisible(false);
      setConfirmLoading(false);
      console.log('sollte eig gelöscht sein');
      props.reloadElemente();
    }).catch(err => {
      console.log(err);
      setVisible(true);
      setConfirmLoading(false);
    });
  };

    const showModal = (record) => {
    setElementToDelete(record);
    setVisible(true);
  };

    const handleOk = () => {
    setConfirmLoading(true);
    deleteElementFromDb(elementToDelete.id);
  };

  const handleCancel = () => {
    console.log('Clicked cancel button');
    setVisible(false);
  };


  const columns = [{
      title: 'Elementname',
      dataIndex: 'titel',
      key: 'titel',
    },
    {
      title: 'Kategorie',
      key: 'kategorie',
      dataIndex: 'kategorie',
      filters: [{
          text: 'Videoüberwachungssysteme',
          value: 'Videoüberwachungssysteme',
        },
        {
          text: 'Demo',
          value: 'Demo',
        },
      ],
      onFilter: (value, record) => {
        console.log(`VALUE = ${value} - RECORD = ${JSON.stringify(record)}`);
        return record && record.kategeorie && record.kategeorie.map((a) => { return a.toLowerCase() }).includes(value.toLowerCase());
      },
      render: tags => {

        tags = Array.isArray(tags) ? tags : [];
        const numTags = tags.length;
        return (
          <React.Fragment>
            {tags.map((tag, i) => {
              return (
                <React.Fragment>
                  <Tag color={'green'}>
                    {tag.toUpperCase()}
                  </Tag>
                  {numTags !== i + 1 && // Not last element
                  <React.Fragment>/</React.Fragment>
                  }
                </React.Fragment>

              );
            })}
          </React.Fragment>
        );
      },
    },
    {
      title: 'Aktionen',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
        <Link to={`/objekt/${record.objektId}/building/${record.buildingId}/part/${record.buildingpartId}/element/${record.id}/details`}>Details</Link>
        <Link to={`/objekt/${record.objektId}/building/${record.buildingId}/part/${record.buildingpartId}/element/${record.id}/edit`}>Bearbeiten</Link>
        <Link to={`#`} onClick={() => showModal(record)}>Löschen</Link>

      </Space>
      ),
    },
  ];

  return (
      <React.Fragment>
        <Table dataSource={props.elemente} columns={columns} loading={props.loading} rowKey="id"/>
        <Modal
            title="Soll das Element wirklich gelöscht werden?"
            visible={visible}
            onOk={handleOk}
            confirmLoading={confirmLoading}
            onCancel={handleCancel}
        >
          <p>Damit wird das Element <b>{elementToDelete.titel}</b> unwiederruflich gelöscht!</p>
        </Modal>
      </React.Fragment>

  );
};

export default ElementList;
