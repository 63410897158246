import React, {useState, useEffect} from 'react';
import {Button, Card, Col, PageHeader, Row, Statistic, Modal, Form, Select} from 'antd';
import moment from 'moment';

import ApiWrapper from '../Utils/ApiWrapper';

import SiteLayout from '../Components/Styled/SiteLayout';
import {Link, useParams} from "react-router-dom";
import {getActiveAccountId, translatePruefer} from "../Utils/helpers";
import ChangeBuildingpart from "../Components/ChangeBuildingAndPart";
import CollapsibleCard from "../Components/Styled/CollapsibleCard";

const ElementDetailsContainer = (props) => {

    const params = useParams();

    const [visible, setVisible] = useState(false);
    const [modalChangeBuildingpartVisible, setModalChangeBuildingpartVisible] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [elementToDelete, setElementToDelete] = useState({});
    const [elementDetails, setElementDetails] = useState({
        partInfo: {}
    });
    const [loading, setLoading] = useState(false);
    const objektId = params.objektId;
    const elementId = params.elementId;
    const buildingId = params.buildingId;
    const buildingpartId = params.buildingpartId;

    const loadElement = async () => {
        setLoading(true);

        const accountData = props.accountData;

        if (accountData && accountData.objekte) {
            for (let objekt of accountData.objekte)
            {
                if (objekt.id === objektId) {
                    for (const building of objekt.buildings) {
                        for (const part of building.parts) {
                            for (let element of part.elemente)
                            {
                                if (element.id === elementId) {
                                    element.partInfo = part;
                                    setElementDetails(element);
                                }
                            }
                        }
                    }
                }
            }
        }

        setLoading(false);
    };

    const deleteElementFromDb = () => {
        ApiWrapper.del('objekt-verwaltung-api', `/${props.activeAccountId}/objekte/${objektId}/elemente/${elementId}/delete`, {}).then((result) => {
            setVisible(false);
            setConfirmLoading(false);
        }).catch(err => {
            console.log(err);
            setVisible(true);
            setConfirmLoading(false);
        });
    };

    const showModal = () => {
        setElementToDelete(elementId);
        setVisible(true);
    };

    const showModalEditBuildingPart = () => {
        setElementToDelete(elementId);
        setModalChangeBuildingpartVisible(true);
    };

    const handleOk = () => {
        setConfirmLoading(true);
        deleteElementFromDb(elementToDelete.id);
    };

    const handleCancel = () => {
        console.log('Clicked cancel button');
        setVisible(false);
    };

    const handleOkModalBuildingpart = () => {
        console.log('Clicked cancel button');
        setModalChangeBuildingpartVisible(false);
    };

    const handleCancelModalBuildingpart = () => {
        console.log('Clicked cancel button');
        setModalChangeBuildingpartVisible(false);
    };

    useEffect(async () => {
        await loadElement();
    }, []);

    const rendernaechstePruefungen = () => {
        const items = [];

        if (elementDetails.naechstePruefungen) {
            for (const [i, naechstePruefungen] of elementDetails.naechstePruefungen.entries()) {
                if (!naechstePruefungen.preview) {
                    items.push(
                        <Card key={`${i + 1}# ${naechstePruefungen.titel}`} type="inner"
                              title={`${i + 1}# ${naechstePruefungen.titel}`}>

                            <Row gutter={16}>
                                <Col span={12}>
                                    <Statistic title="Titel" value={naechstePruefungen.titel}/>
                                    <Statistic title="Beschreibung" value={naechstePruefungen.beschreibung}/>
                                </Col>
                                <Col span={12}>
                                    <Statistic title="Letzte Prüfung"
                                               value={moment(naechstePruefungen.letztePruefung).format('DD.MM.YYYY')}/>
                                    <Statistic title="Nächste Prüfung"
                                               value={moment(naechstePruefungen.naechstePruefung).format('DD.MM.YYYY')}/>
                                    <Statistic title="Prüfung von" value={naechstePruefungen.pruefungVon}/>
                                </Col>
                            </Row>
                        </Card>
                    );
                }
            }
        }
        return items;
    };

    const downloadFile = async (attachmentId) => {
        await ApiWrapper.download('objekt-verwaltung-api', `/${getActiveAccountId()}/objekte/${objektId}/download/${attachmentId}`)
    }

    const renderAttachments = (vergangenePruefungen) => {

        if (!vergangenePruefungen.attachments || !Array.isArray(vergangenePruefungen.attachments)) {
            return(<p>Keine Anhänge vorhanden</p>)
        }

        const attachments = [];

        for (const att of vergangenePruefungen.attachments) {
            attachments.push(<Link to={""} onClick={() => downloadFile(att.id)}>-> {att.name}</Link>);
        }

        return attachments;

    }

    const rendervergangenePruefungen = () => {
        const items = [];

        if (elementDetails.vergangenePruefungen) {
            for (const [i, vergangenePruefungen] of elementDetails.vergangenePruefungen.entries()) {

                items.push(
                    <Card key={`${i + 1}# ${vergangenePruefungen.titel}`} type="inner"
                          title={`${i + 1}# ${vergangenePruefungen.titel}`} extra={<Link to={`/objekt/${vergangenePruefungen.objektId}/building/${buildingId}/part/${buildingpartId}/element/${vergangenePruefungen.elementId}/pruefung/${vergangenePruefungen.id}/details`}>Details</Link>}>

                        <Row gutter={16}>
                            <Col span={12}>
                                <Statistic title="Titel" value={vergangenePruefungen.titel}/>
                                <Statistic title="Beschreibung" value={vergangenePruefungen.beschreibung}/>
                            </Col>
                            <Col span={12}>
                                <Statistic title="Prüfung durchgeführt"
                                           value={moment(vergangenePruefungen.letztePruefung).format('DD.MM.YYYY')}/>
                                <Statistic title="Prüfungsfrist"
                                           value={moment(vergangenePruefungen.naechstePruefung).format('DD.MM.YYYY')}/>
                                <Statistic title="Prüfung von" value={translatePruefer(vergangenePruefungen.pruefungVon)}/>
                            </Col>
                        </Row>

                        <h2>Anhänge</h2>
                        {renderAttachments(vergangenePruefungen)}

                    </Card>
                );

            }
        }
        return items;
    };


    return (
        <SiteLayout pageTitle="Detailansicht Element" breadcumItems={[
            <Link to={`/objekte`}>Account</Link>,
            <Link to={`/objekt/${objektId}`}>Objekt</Link>,
            'Element'
        ]}>
            <PageHeader
                ghost={false}
                title={elementDetails.titel}
                extra={[
                    <Button key="1"><Link to={`/objekt/${objektId}/building/${buildingId}/part/${buildingpartId}/element/${elementId}/edit`}>Bearbeiten</Link></Button>,
                    <Button key="2" onClick={() => showModalEditBuildingPart()}>Raum ändern</Button>,
                    <Button key="3" onClick={() => showModal()}>Löschen</Button>,
                    <Button key="4"><Link to={`/objekt/${objektId}/pruefungen/dokumentieren`}>Prüfung
                        dokumentieren</Link></Button>,
                ]}
            >
            </PageHeader>
            <Card title="Details" bordered={true} style={{'text-align': 'left', 'margin': '10px 0'}} loading={loading}>
                <Row gutter={16}>
                    <Col span={12}>
                        <Statistic title="Name" value={elementDetails.titel}/>
                        <Statistic title="Beschreibung" value={elementDetails.beschreibung}/>
                        <Statistic title="Etage" value={elementDetails.partInfo.floor ?? ''}/>
                        <Statistic title="Raum" value={elementDetails.partInfo.name ?? ''}/>
                    </Col>
                    <Col span={12}>
                        <Statistic title="Angelegt am" value={moment(elementDetails.createdAt).format('DD.MM.YYYY')}/>
                        <Statistic title="Eingebaut am"
                                   value={moment(elementDetails.einbauDatum).format('DD.MM.YYYY')}/>
                        <Statistic title="Geräte ID" value={elementDetails.geraeteId}/>
                        <Statistic title="Zuletzt bearbeitet"
                                   value={moment(elementDetails.updatedAt).format('DD.MM.YYYY')}/>
                    </Col>
                </Row>
            </Card>
            <CollapsibleCard title="Vorgesehene Prüfungen" bordered={true} style={{'text-align': 'left', 'margin': '10px 0'}}>
                {rendernaechstePruefungen()}
            </CollapsibleCard>
            <CollapsibleCard title="Vergangene Prüfungen" bordered={true} style={{'text-align': 'left', 'margin': '10px 0'}}>
                {rendervergangenePruefungen()}
            </CollapsibleCard>
            <React.Fragment>
                <Modal
                    title="Soll das Element wirklich gelöscht werden?"
                    visible={visible}
                    okText={"OK"}
                    onOk={handleOk}
                    confirmLoading={confirmLoading}
                    onCancel={handleCancel}
                >
                    <p>Damit wird das aktuelle Element <b>{elementDetails.titel}</b> unwiederruflich gelöscht!</p>
                </Modal>
                <Modal
                    title="Gebäudeteil für das Element ändern"
                    visible={modalChangeBuildingpartVisible}
                    okText={"OK"}
                    onOk={handleOkModalBuildingpart}
                    onCancel={handleCancelModalBuildingpart}
                >
                    <ChangeBuildingpart element={elementDetails}/>
                </Modal>
            </React.Fragment>
        </SiteLayout>
    );
};
export default ElementDetailsContainer;
