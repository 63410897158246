import React, {useState, useEffect} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {DatePicker, Cascader, Select, Button, Form, PageHeader, Input, Tooltip, notification, Modal} from 'antd';
import {MinusOutlined, PlusOutlined, InfoCircleOutlined, ExclamationCircleOutlined} from '@ant-design/icons';

import ApiWrapper from '../Utils/ApiWrapper';

import SiteLayout from '../Components/Styled/SiteLayout';

import Element from "../Components/Element";

import moment from 'moment';

import {getActiveAccountId} from "../Utils/helpers";

const {Option} = Select;
const {confirm} = Modal;

const ElementEditContainer = (props) => {
    const [form] = Form.useForm();

    const params = useParams();

    const [elementDetails, setElementDetails] = useState({});
    const [loading, setLoading] = useState(true);
    const [element, setElement] = useState([]);
    const objektId = params.objektId;
    const elementId = params.elementId;
    const buildingId = params.buildingId;
    const buildingpartId = params.buildingpartId;

    const loadElement = () => {

        const accountData = props.accountData;
        if (accountData && accountData.objekte) {
            for (let objekt of accountData.objekte) {
                for (const building of objekt.buildings) {
                    for (const parts of building.parts) {
                        for (let element of parts.elemente) {
                            if (element.id === elementId) {
                                setElement(element);
                            }
                        }
                    }
                }
            }
        }
    };

    useEffect(async () => {
        setLoading(true);
        loadElement();
        setLoading(false);
    }, [props.accountData]);

    const onOk = async () => {
        const values = await form.getFieldsValue(true);
        console.log(values);

        ApiWrapper.post('objekt-verwaltung-api', `/${getActiveAccountId()}/object/${objektId}/building/${activGebaeude.id}/part/${activGebaeudeteil.id}/observable`, values).then(async (result) => {

        }).catch(err => {
            console.log(err);
        });
    }


    const renderBody = () => {
        if (loading) {
            return null;
        } else {

            return (
                <div style={{width: "80%"}}>
                    <Element form={form} objektId={objektId} element={element}/>
                </div>
            );
        }

    }

    return (
        <SiteLayout pageTitle="Element Bearbeiten" breadcumItems={[
            <Link to={`/objekte`}>Account</Link>,
            <Link to={`/objekte`}>Objektübersicht</Link>,
            <Link to={`/objekt/${objektId}`}>Objekt</Link>,
            <Link to={`/objekt/${objektId}/building/${buildingId}/part/${buildingpartId}/element/${elementId}/details`}>Element</Link>,
            `Bearbeiten`
        ]}>

            <PageHeader
                ghost={false}
                title={`${elementDetails.titel}`}
            />

            <br/>

            {renderBody()}

        </SiteLayout>
    );
};
export default ElementEditContainer;
