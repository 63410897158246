import {Link, useNavigate, useLocation} from 'react-router-dom';
import React, { useState } from 'react';
import Logo from '../Img/logo_schrift_blau.png';

import { Layout, Menu, notification, Breadcrumb } from 'antd';
import {
  TeamOutlined,
  UserOutlined,
  HomeOutlined,
  VideoCameraOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  SettingOutlined,
  SwitcherOutlined,
  EditOutlined,
  CodeOutlined
}
from '@ant-design/icons';

import { Auth } from 'aws-amplify';
/** App Constatns */
import { AUTH_USER_TOKEN_KEY } from '../../Utils/constants';

import './SiteLayout.css';

import { hasUserMultipleAccounts, getActiveAccountId, isAdmin } from '../../Utils/helpers';

import 'antd/dist/antd.dark.css';

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;


const SiteLayout = (props) => {
  const accountId = getActiveAccountId();

  const { children } = props;

    let navigate = useNavigate();

    const { pathname } = useLocation();

  const handleLogout = async() => {

    try {
      await Auth.signOut({ global: true }).then(() => {
        localStorage.removeItem(AUTH_USER_TOKEN_KEY);
        navigate('/login');
      });
    }
    catch (err) {
      notification.error({ message: err.message });
    }
  };

  const [collapsed, setCollapsed] = useState(false);

  const toggle = () => {
    setCollapsed(!collapsed);
  };

/* Alle Unterseiten bzgl. Objekte togglen die Objektübersicht im Sider */
  if (pathname.includes('/objekt'))
  {
    var objectPlaceholder = pathname;
  };


  var i='0';
  const renderBreadcumItems = () => {
      const items = [];
      if (props.breadcumItems) {
          for (const item of props.breadcumItems) {
              i++;
              items.push(<Breadcrumb.Item key={i}>{item}</Breadcrumb.Item>);
          }
      }
      return items;
  };

  return (
    <Layout>
            <Sider
              collapsible
            >
              <div className="logo">
                  <img src={Logo} alt="Logo"/>
              </div>
              <Menu theme="dark" mode="inline" defaultSelectedKeys={[pathname]} >
               <Menu.Item key="/uebersicht" icon={<HomeOutlined />} >
                  Startseite
                  <Link to="/uebersicht" />
                </Menu.Item>
                <Menu.Item key={`${objectPlaceholder}`} icon={<SwitcherOutlined />} >
                  Objektübersicht
                  <Link to="/objekte" />
                </Menu.Item>
                <Menu.Item key={`/${accountId}/pruefungen`} icon={<VideoCameraOutlined />}>
                  Prüfungen
                  <Link to={`/${accountId}/pruefungen`} />
                </Menu.Item>

                <SubMenu key="SubMenuNutzerverwaltung" icon={<UserOutlined />} title="Nutzerverwaltung">
                    {
                        isAdmin() ? <Menu.Item key='/admin/usermanagement' icon={<CodeOutlined />} > Nutzerverwaltung (Admins) <Link to='/admin/usermanagement' /> </Menu.Item> : null
                    }

                    <Menu.Item key={`/account/users`}>
                        Nutzerverwaltung
                        <Link to={`/account/users`} />
                    </Menu.Item>
                </SubMenu>

                  <SubMenu key="SubMenuSettings" icon={<SettingOutlined />} title="Voreinstellungen">
                      {
                          isAdmin() ? <Menu.Item key='/pruefungsart' icon={<CodeOutlined />}>  Prüfungsarten (A.) <Link to='/pruefungsart' /> </Menu.Item> : null
                      }
                      {
                          isAdmin() ? <Menu.Item key='/voreinstellung' icon={<CodeOutlined />}> Voreinstellungen (A.) <Link to='/voreinstellung' /> </Menu.Item> : null
                      }
                      {
                          isAdmin() ? <Menu.Item key='/settings/notifications' icon={<CodeOutlined />}> Benachrichtigungen (A.)<Link to='/settings/notifications' /> </Menu.Item> : null
                      }
                  </SubMenu>


                 <SubMenu key="SubMenu" icon={<EditOutlined />} title="Profil">
                 <Menu.Item key="profil:1">Passwort wechseln<Link to="/benutzer/passwort-aendern"/></Menu.Item>
                 {
                   hasUserMultipleAccounts() ? <Menu.Item key="profil:2">Account wechseln<Link to="/account/wechseln" /></Menu.Item> : null
                 }
                  <Menu.Item key="profil:3" onClick={handleLogout}>Ausloggen</Menu.Item>
               </SubMenu>
              </Menu>
            </Sider>
            <Layout className="site-layout" style={{ padding: 0 }}>
              <Header className="site-layout-background" style={{ padding: 0 }}/>

              <Content style={{ margin: '24px 16px 0', 'overflow-y': 'scroll' }}>
              <Breadcrumb style={{ padding: '0 24px' }}>
                  {renderBreadcumItems()}
             </Breadcrumb>
                <div className="site-layout-background" style={{ padding: 24, textAlign: 'center' }}>
                  {children}
                </div>
              </Content>

            <Footer style={{ textAlign: 'center', padding: 4}}>
                Pruver GmbH ©2022
            </Footer>
            </Layout>

        </Layout>
  );

};

export default SiteLayout;
