import React, {useState, useEffect} from 'react';

import {Skeleton} from 'antd';
import SiteLayout from '../Components/Styled/SiteLayout';
import ApiWrapper from "../Utils/ApiWrapper";
import {setActiveAccountName} from "../Utils/helpers";

const ContextWrapper = ({component: Component, activeAccountId}) => {

    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);

    const loadData = async () => {
        try {
            setLoading(true);
            const result = await ApiWrapper.get('objekt-verwaltung-api', `/accounts/${activeAccountId}/details`, {});
            setActiveAccountName(result.titel);
            setData(result);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    useEffect(async () => {
        await loadData();
    }, []);

    useEffect(async () => {
        await loadData();
    }, [activeAccountId]);

    if (!data || loading) {
        return <SiteLayout><Skeleton/></SiteLayout>;
    } else {
        return (
            <Component activeAccountId={activeAccountId} accountData={data} reloadData={loadData}/>
        );
    }

};

export default ContextWrapper;
